import React from "react";
import Chart from "react-apexcharts";
const TopCyberThreats = ({ darkMode }) => {
  return (
    <div className="p-0">
      <Chart
        type="pie"
        width={450}
        height={210}
        series={[20, 15, 25, 30, 10]}
        options={{
          labels: [
            "Phishing",
            "Zero-Day Exploits",
            "Supply Chain Attacks",
            "DDoS",
            "Credential Stuffing",
          ],

          stroke: {
            width: 0,
          },
          dataLabels: {
            style: {
              fontSize: "14px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold",
            },
            enabled: true,
            formatter: function (val, opts) {
              /* Format labels here */
              return opts.w.config.series[opts.seriesIndex];
            },
          },
          //start
          responsive: [
            {
              breakpoint: 535,
              options: {
                chart: {
                  width: 320,
                },
                legend: {
                  offsetY: -13,
                  offsetX: 0,
                },
              },
            },
          ],
          //finish
          legend: {
            fontSize: "14px",
            fontWeight: "bold",
            itemMargin: "left",
            labels: {
              colors: darkMode ? "#979ea5" : "black",
            },
          },
        }}
      />
    </div>
  );
};

export default TopCyberThreats;
