import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  cpeValue,
  domain,
  vendors,
} from "../../../../authentication/store/actions/index";
import { useSelector, useDispatch } from "react-redux";
import { GET_CLIENT_BIA } from "../../../../graphql/client/query";
import { useQuery } from "@apollo/client";
import LoadingSpinner from "../../../../utils/LoadingSpinner";

const VendorDetailsBrief = ({ showHide }) => {
  const dispatch = useDispatch();
  const {
    data: biaClient,
    loading: biaLoading,
    refetch: fetchData,
  } = useQuery(GET_CLIENT_BIA, {
    variables: { limit: 10 },
  });
  const uniqueVendors = [
    ...new Map(
      biaClient?.client_asset?.map((vendor) => [vendor?.vendor, vendor])
    ).values(),
  ];
  const DisplayData = uniqueVendors.map((vendor) => (
    <div
      key={vendor.id}
      className="flex flex-col space-y-3"
      onClick={() => {
        dispatch(vendors(vendor?.vendor));
        dispatch(cpeValue(vendor?.cpe));
        dispatch(domain(vendor?.vendor?.toLowerCase() + ".com"));
      }}
    >
      {biaLoading ? (
        <LoadingSpinner />
      ) : (
        <Link
          className="flex flex-col space-y-2 mt-2 justify-center items-center link_hover text-lg font-semibold text-gray-700 dark:text-gray-200"
          to="/company-profile"
        >
          {vendor?.vendor}
        </Link>
      )}
    </div>
  ));

  return <div>{DisplayData}</div>;
};

export default VendorDetailsBrief;
