import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { baseURL } from "../resources/apiClient";
import { useApi } from "../hooks/useApis";
import { toLowercase } from "../utils/helpers";
import { getBackgroundColor } from "../utils/getBackgroundColor";

const TopThreats = () => {
  const { data, isLoading, fetchData, isError } = useApi(
    `${baseURL}/threat-catalog/top-threats`
  );

  const dataPerPage = 6;
  // page pagination
  const [pageNumber, setPageNumber] = useState(0);
  // const dataPerPage = 7;
  const pagesVisited = pageNumber * dataPerPage;
  const pageCount = Math.ceil(data?.length / dataPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  console.log(data);
  const DisplayData = data
    ?.slice(pagesVisited, pagesVisited + dataPerPage)
    ?.map((items) => {
      return (
        <tr className="text-2xl h-28 ">
          <td className="text-center">{items?.threat}</td>
          <td className="text-center">
            <p
              className="p-3 text-center rounded-xl"
              style={{
                backgroundColor: getBackgroundColor(
                  toLowercase(items?.risk_profile)
                ),
                color: "black",
              }}
            ></p>
          </td>
          <td className="text-right">{items?.reputation}%</td>
        </tr>
      );
    });
  return (
    <div className="flex flex-col h-full w-full justify-evenly relative overflow-hidden  ">
      <table className="text-black tables h-full">
        <thead>
          <tr>
            <th className="">Threat Name</th>
            <th className="">Application Risk Profile</th>
            <th className="">Reputation</th>
          </tr>
        </thead>
        <tbody> {DisplayData}</tbody>
      </table>
      {data?.length > 6 ? (
        <div className="paginationBttns">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            activeClassName={"paginationActive"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
          />
        </div>
      ) : null}
    </div>
  );
};

export default TopThreats;
