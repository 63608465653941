import React from "react";

const SmallCard = ({ title, value, clientCurrencySymbol = "" }) => {
	return (
		<div className="bg-white dark:bg-gray-800   w-full h-full shadow rounded-lg p-4 dark:border-gray-700 transition-all duration-300 hover:shadow-xl">
			<div className="flex items-center justify-between">
				<h3 className="text-lg font-semibold text-gray-500 dark:text-gray-400">
					{title}
				</h3>
				<span className="text-gray-500 dark:text-gray-400">
					<i className="fas fa-info-circle"></i>
				</span>
			</div>
			<p className="mt-2 text-3xl font-semibold text-gray-900 dark:text-white">
				{clientCurrencySymbol}
				{value}
			</p>
		</div>
	);
};

export default SmallCard;
