import React, { useState, useEffect } from "react";
import { useMutation, gql, useQuery } from "@apollo/client";
import Modal from "../../shared/Modal";
import Form from "../../shared/Form";
import LoadingComponent from "../../shared/LoadingDropDownSelection/LoadingComponent";
import swal from "sweetalert";
import { CREATE_CLIENT_ASSET, UPDATE_CLIENT_BIA } from "../../graphql/client/query";
import { GET_BIA_SUPER_ADMIN } from "../../graphql/superAdmin/query/query";
import { options } from "../../data/data";
import toast from "react-hot-toast";

const ModalHandler = ({
  isOpen,
  selectedItemId,
  onClose,
  mode,
  size,
  header,
  selectedItem,
  fetchData,
  selectedClientId,
  selectedBusinessProcessId,
}) => {
  const [fetchLoading, setFetchLoading] = useState(false);
  console.log(selectedItem,"selected items")

  const initialState = {
    name: "",
    vendor: "",
    product: "",
    version: "",
    cpe: "",
    domain: "",
    business_process: selectedBusinessProcessId,
    client: selectedClientId,
  };
  const [page, setPage] = useState(0);
  const pageSize = 10; // Number of items per page
  const { data, loading, error, refetch } = useQuery(GET_BIA_SUPER_ADMIN, {
    variables: { limit: 1000, offset: page * pageSize },
  });

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});

  const validations = {
    hierarchy: (value) => (value ? "" : "This field is required."),
    business_assets: (value) => (value ? "" : "This field is required."),
  };

  const [selectedVendor, setSelectedVendor] = useState(""); // Track the selected vendor

  const handleChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
    if (validations[key]) {
      const error = validations[key](value);
      setErrors((prevState) => ({ ...prevState, [key]: error }));
    }

    // Update the selected vendor
    if (key === "vendor") {
      setSelectedVendor(value);
    }
  };

  // Filter assets based on the selected vendor
  const filteredAssetOptions = selectedVendor
    ? data?.asset
        ?.filter((item) => item.vendor === selectedVendor)
        .map((item) => item.name ?? "")
    : [];

  const resetForm = () => {
    setFormData(initialState);
    setErrors({});
  };
  useEffect(() => {
    // Automatically set CPE based on the selected asset and version
    if (formData.name && formData.version) {
      const selectedAsset = data?.asset?.find(
        (item) =>
          item.name === formData.name && item.version === formData.version
      );
      if (selectedAsset) {
        setFormData((prevState) => ({
          ...prevState,
          cpe: selectedAsset.cpe || "", // Default to empty string if no CPE
        }));
      }
    } else {
      // Reset CPE if name or version is not selected
      setFormData((prevState) => ({ ...prevState, cpe: "" }));
    }
  }, [formData.name, formData.version, data]);

  useEffect(() => {
    if (mode === "edit" && selectedItem) {
      setFetchLoading(true);
      setFormData(selectedItem);
      setFetchLoading(false);
    } else {
      resetForm();
    }
  }, [mode, selectedItem]);

  const [createClientAsset] = useMutation(CREATE_CLIENT_ASSET);
  const [updateClientAsset] = useMutation(UPDATE_CLIENT_BIA);

  if (loading) return <LoadingComponent />;
  if (error) return <div>Error loading asset names</div>;

  const assetOptions = data?.asset?.map((item) => item.name ?? "") || [];
  const vendorOptions = data?.asset?.map((item) => item.vendor ?? "") || [];
  const versionOptions = data?.asset?.map((item) => item.version ?? "") || [];
  console.log(assetOptions, "asset options");
  console.log(formData, "formData");
  const handleSubmit = async () => {
    let newErrors = {};
    Object.entries(validations).forEach(([key, validate]) => {
      newErrors[key] = validate(formData[key]);
    });
    setErrors(newErrors);

    try {
      const { data } = await createClientAsset({
        variables: {
          inputs: {
            created_at: new Date().toISOString().split("T")[0], // Or use a custom date
            name: formData.name,
            cpe: formData.cpe,
            version: formData.version,
            vendor: formData.vendor,
            risk: "high",
            client_pk: selectedClientId,
            business_process_pk: selectedBusinessProcessId,
          },
        },
      });

      if (data.create_client_asset.affected_rows > 0) {
        toast.success("Asset added successfully!");
        fetchData();
        resetForm();
        onClose();
      }
    } catch (error) {
      const graphQLError =
        error?.graphQLErrors?.[0]?.message || "An unknown error occurred.";

      // Handle specific database constraint error
      if (
        graphQLError.includes(
          "duplicate key value violates unique constraint"
        ) &&
        graphQLError.includes("atc_clientname_key")
      ) {
        toast.error(
          `The asset name "${formData.name}" already exists. Please choose a unique asset.`
        );
      } else {
        toast.error(graphQLError);
      }
      console.error("GraphQL Error:", error);
    }
  };

  const handleUpdate = async () => {
    let newErrors = {};
    Object.entries(validations).forEach(([key, validate]) => {
      newErrors[key] = validate(formData[key]);
    });
    setErrors(newErrors);

    try {
      const { data } = await updateClientAsset({
        variables: {
          id:parseInt(selectedItemId),
          input: {
            name: formData.name,
            cpe: formData.cpe,
            version: formData.version,
            vendor: formData.vendor,
          },
        },
      });

      if (data.update_client_asset.affected_rows > 0) {
        toast.success("Asset added successfully!");
        fetchData();
        resetForm();
        onClose();
      }
    } catch (error) {
      const graphQLError =
        error?.graphQLErrors?.[0]?.message || "An unknown error occurred.";

      // Handle specific database constraint error
      if (
        graphQLError.includes(
          "duplicate key value violates unique constraint"
        ) &&
        graphQLError.includes("atc_clientname_key")
      ) {
        toast.error(
          `The asset name "${formData.name}" already exists. Please choose a unique asset.`
        );
      } else {
        toast.error(graphQLError);
      }

      console.error("GraphQL Error:", error);
    }
  };

  const formFieldsConfig = {
    vendor: {
      label: "vendor",
      type: "select",
      placeholder: "Select a vendor...",
      value: formData.vendor,
      options: vendorOptions,
      onChange: (e) => handleChange("vendor", e.target.value),
      error: errors.vendor,
      disabled: false, // vendor are always enabled
    },
    name: {
      label: "Asset Name",
      type: "select",
      placeholder: "Select an asset...",
      value: formData.name,
      options: filteredAssetOptions, // Use the filtered list of assets
      onChange: (e) => handleChange("name", e.target.value),
      error: errors.name,
      disabled: !formData.vendor, // Disabled if no vendor is selected
    },
    version: {
      label: "Version",
      type: "select",
      placeholder: "Select a version...",
      value: formData.version,
      options: versionOptions,
      onChange: (e) => handleChange("version", e.target.value),
      error: errors.version,
      disabled: !formData.name, // Disabled if no asset is selected
    },
    // domain: {
    //   label: "Domain",
    //   type: "text",
    //   placeholder: "Enter domain...",
    //   value: formData.domain,
    //   onChange: (e) => handleChange("domain", e.target.value),
    //   error: errors.domain,
    //   disabled: !formData.version, // Disabled if no version is selected
    // },
    cpe: {
      label: "CPE",
      type: "text",
      placeholder: "Enter CPE...",
      value: formData.cpe,
      onChange: (e) => handleChange("cpe", e.target.value),
      error: errors.cpe,
      disabled: true, // CPE is always disabled
    },
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <Modal.Header>
        <Modal.Title>
          {mode === "edit" ? `Update ${header}` : `Add New ${header}`}
        </Modal.Title>
      </Modal.Header>
      {fetchLoading ? (
        <LoadingComponent />
      ) : (
        <Modal.Body>
          <div className="modal-two-columns">
            <Form>
              {Object.entries(formFieldsConfig).map(([key, fieldConfig]) => (
                <Form.Group key={key}>
                  <Form.Label>{fieldConfig.label}</Form.Label>
                  <Form.Control {...fieldConfig} />
                  {errors[key] && (
                    <Form.Control.Feedback type="invalid">
                      {errors[key]}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              ))}
            </Form>
          </div>
        </Modal.Body>
      )}
      <Modal.Footer>
        <button
          className="btn-cancel"
          onClick={() => {
            onClose();
            resetForm();
          }}
        >
          Cancel
        </button>
        {mode==="add"?<button className="btn-add-new" onClick={handleSubmit}>
          Add
        </button>:
        <button className="btn-add-new" onClick={handleUpdate}>
          Save Changes
        </button>}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalHandler;
