import DeleteIcon from "@mui/icons-material/Delete";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import http from "../../../resources/http.js";
import { baseURL } from "../../../resources/apiClient.js";
import { GET_MY_BUSINESS_PROCESS } from "../../../api/businessProcess.js";
import { pid } from "../../../authentication/store/actions/index.js";
import swal from "sweetalert";
import { toast } from "react-hot-toast";
import CustomTable from "../../../shared/CustomTable.js";
import ModalHandler from "./ModalHandler.js";
import ScenarioCardComponent from "../ScenarioCardComponent.js";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent.js";
import ComparisonCard from "./ComparisonCard.js";
import Modal from "../../../shared/Modal.js";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_BUSINESS_PROCESS, GET_INVESTMENT_ANALYSIS, GET_INVESTMENT_ANALYSIS_RESULT, GET_INVESTMENT_SCENARIOS, SAVE_INVESTMENT_SCENARIO, SAVE_INVESTMENT_SCENARIO_DATA, UPDATE_INVESTMENT_SCENARIO } from "../../../graphql/client/query.js";
import TableLoadingAnimation from "../../../shared/TableLoading/TableLoadingAnimation.js";
import EditIcon from '@mui/icons-material/Edit';
import { DeleteModal } from "../../../shared/DeleteModal.js";

const InvestmentAnalysis = () => {
	const [error, setError] = useState(null);
	const [fetchLoading, setFetchLoading] = useState(false);
	const [BIAData, setBIAData] = useState([]);
	const [InvestmentAnalysisData, setInvestmentAnalysisData] = useState();
	const [isNullOptions, setIsNullOptions] = useState("");
	const [isNullData, setIsNullData] = useState(false);
	const [selectedClientId, setSelectedClientId] = useState(null);
	const [clientBusinessProcessList, setClientBusinessProcessList] = useState(
		[]
	);
	const [selectedBusinessProcessId, setSelectedBusinessProcessId] =
		useState(null);
	const [savedScenarioo, setSavedScenario] = useState();
	const ref = useRef(null);

  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = () => setShowFilters(!showFilters);

  const [showSorts, setShowSorts] = useState(false);
  // useCallback to memoize the toggleSorts function and prevent re-creation
  const toggleSorts = useCallback(() => {
    setShowSorts((prevShowSorts) => !prevShowSorts);
  }, []);
  const dispatch = useDispatch();

  const [showResult, setShowResult] = useState(false);
  const showResults = () => setShowResult(!showResult);
  const [showAnalysis, setShowAnalysis] = useState(false);

  const toggleAnalysis = () => setShowAnalysis(!showAnalysis);
  const [comparison, setShowComparison] = useState(false);

  const toggleComparison = () => setShowComparison(!comparison);
  const [scenarioName, setScenarioName] = useState('');
  const queryClient = useQueryClient();
  const processId = useSelector((state) => state.pid);

  const [investmentResult, setInvestmentResult] = useState({});
  const [id, setId] = useState(0);

  const [scenarios, setScenarios] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [isComparisonModalOpen, setComparisonModalOpen] = useState(false);
  const [mode, setMode] = useState('add'); // 'add' or 'edit'
  const [selectedItem, setSelectedItem] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});

  const [selectedIds, setSelectedIds] = useState([]);
  const [checkedImplementedItems, setCheckedImplementedItems] = useState({});

  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
  };

	const scrollToTable = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};
	// business process for option on load
	// useEffect(() => {
	// 	const fetchBusinessProcessOption = async () => {
	// 		setFetchLoading(true);
	// 		try {
	// 			const response = await http.get(`${GET_MY_BUSINESS_PROCESS}`);
	// 			if (!response.data || response.data?.length === 0) {
	// 				setIsNullOptions("No business process at the moment ");
	// 				setClientBusinessProcessList([]);
	// 			} else {
	// 				const simplifiedBPOptions = response.data.map(({ id, name }) => ({
	// 					id,
	// 					name,
	// 				}));
	// 				setClientBusinessProcessList(simplifiedBPOptions);
	// 			}
	// 		} catch (error) {
	// 			console.log(error);
	// 		} finally {
	// 			setFetchLoading(false);
	// 		}
	// 	};

	// 	fetchBusinessProcessOption();
	// }, []);
	const { data } = useQuery(GET_BUSINESS_PROCESS, {
		variables: { limit: 100},
	  });
	const { data:investmentAnalysis, loading, errors, refetch } = useQuery(GET_INVESTMENT_ANALYSIS, {
		variables: { limit: 100},
	  });

	  console.log(investmentAnalysis,'investmentAnalysis')

	// Handle dropdown selection
	const handleSelectionChange = (e) => {
		if (e.target.value === "Select application/process") {
			setSelectedBusinessProcessId();
			return;
		} else {
			setSelectedBusinessProcessId(e.target.value);
			dispatch(pid(e.target.value));
			setInvestmentResult({});
		}
	};
	
	  const { data:savedScenario, refetch:refetchScenario } = useQuery(GET_INVESTMENT_SCENARIOS, {
		variables: { limit: 100},
	  });

	  // The replacement for the async function
	  const fetchInvestmentAnalysisData = () => {
		if (!selectedBusinessProcessId) return;
	
		setFetchLoading(true);
		
	  };

  // map back the scenario id on the table
  const handleListSelection = (appliedControlIds) => {
    const newCheckedItems = {};
    appliedControlIds.forEach((id) => {
      newCheckedItems[id] = true;
    });
    setCheckedImplementedItems(newCheckedItems);
  };

	const handleImplementedCheckboxChange = useCallback((id, isChecked) => {
		setCheckedImplementedItems((prevState) => ({
			...prevState,
			[id]: isChecked,
		}));
	}, []);
	
	console.log(investmentResult,'investmentResult');
	const [getInvestmentAnalysisResult, { data:resultData}] = useLazyQuery(GET_INVESTMENT_ANALYSIS_RESULT, {
		onCompleted: (resultData) => {
		  setInvestmentResult(resultData.investment_analysis_result);
		  setFetchLoading(false);
		  setIsResultModalOpen(true);
		},
		onError: (err) => {
		  setFetchLoading(false);
		  toast.error('Failed to fetch investment analysis result.');
		},
	  });
	
	  const handleRunInvestmentAnalysisClick = () => {
		const appliedControlIds = Object.keys(checkedImplementedItems).filter(
		  (id) => checkedImplementedItems[id]
		);
	
		if (appliedControlIds.length === 0) {
		  toast.error(
			'Please implement a control and enter an investment amount greater than zero to run the investment analysis.'
		  );
		  return;
		}
	
		// Trigger the GraphQL query
		setFetchLoading(true);
		getInvestmentAnalysisResult({
		  variables: {
			businessProcessId: processId,
			appliedControls: appliedControlIds.map(Number), // Ensure the IDs are integers
		  },
		});
	  };

  const handleCheckboxChange = (id, isChecked) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [id]: isChecked,
    }));

    setSelectedIds((prevState) => {
      if (isChecked) {
        if (prevState?.length < 3) {
          return [...prevState, id];
        } else {
          toast.error('You can only compare up to three items.');
          return prevState;
        }
      } else {
        return prevState.filter((itemId) => itemId !== id);
      }
    });
  };

  const handleComparison = (id) => {
    if (selectedIds?.length < 2) {
      toast.error('Please select at least two items to compare.');
    } else {
      const selectedScenarios = selectedIds?.map((selectedId) => {
        const item = filteredScenarioData.find(
          (item) => item.id === selectedId
        );
        return {
          id: selectedId,
          scope: item.scope,
          senario_name: item.senario_name,
          investment_requirement: item.investment_requirement,
          return_on_investment: item.return_on_investment,
          total_cost_saving: item.total_cost_saving,
          average_lose_exposure: item.average_lose_exposure,
          probability_of_occurrence: item.probability_of_occurrence,
          severity: item.severity,
        };
      });
      setScenarios(selectedScenarios);
      setComparisonModalOpen(true);
    }
  };
  const handleComparisonModal = () => {
    isComparisonModalOpen
      ? setComparisonModalOpen(false)
      : setComparisonModalOpen(true);
  };

	const filterData = () => {
		return investmentAnalysis?.investment_analysis?.map(
			(control) => ({
				id:control?.id,
				control_type:control?.control?.control_type?.label,
				control_domain:control?.control?.control_domain?.label,
				control_category:control?.control?.control_category?.label,
				control_name:control?.control?.name,
				reduction_percentage:control?.reduction_percentage,
				investment:control?.investment,
			})
		);
	};
	const filteredData = filterData();
    console.log(filteredData)
	const DisplayData = filteredData
		?.map((controls, index) => {
			return (
				<tr key={controls.id}>
					
					<td>
						{controls?.control_name??"N/A"}
					</td>
					<td>
						{controls?.control_domain??"N/A"}
					</td>{" "}
					<td>
						{controls?.control_category??"N/A"}
					</td>{" "}
					<td>
						{controls?.control_type??"N/A"}
					</td>
					<td>
						{controls?.reduction_percentage}
					</td>
					<td>
					{controls?.investment}
					</td>
					<td>
						<input
							id={controls.id}
							type="checkbox"
							checked={checkedImplementedItems[controls.id] || false}
							onChange={(e) => handleImplementedCheckboxChange(controls.id, e.target.checked)}
						/>
					</td>

					<td
                        className=" flex justify-center items-center "
                          >
                            <button
                              className="btn-edit"
                              onClick={() => {
                                handleEdit(controls.id, controls);
                              }}
                            >
                              <EditIcon />
                            </button>
                          </td>
					
				</tr>
			);
		});

	const filterScenarioData = (savedScenario) => {
		return savedScenario?.map(
			({
				id,
				senario_name,
				investment_requirement,
				return_on_investment,
				total_cost_saving,
				// actual: {
				// 	scope,
				// 	average_lose_exposure,
				// 	probability_of_occurrence,
				// 	residual_risk: { severity, value },
				// },
				// // applied_controls: [{ id: appliedControlsIds }],
				// applied_controls: [applied_controlsId],
			}) => ({
				id,
				// scope,
				senario_name,
				investment_requirement,
				return_on_investment,
				total_cost_saving,
				// average_lose_exposure,
				// probability_of_occurrence,
				// severity,
				// applied_controlsId,
			})
		);
	};

	// Extracting outer id and all nested ids as an array
	console.log(savedScenario,'savedScenario');
	const finalScenarioData = savedScenario?.investment_analysis?.map(
		({
			id,
			senario_name,
			investment_requirement,
			return_on_investment,
			total_cost_saving,
			// actual: {
			// 	scope,
			// 	average_lose_exposure,
			// 	probability_of_occurrence,
			// 	residual_risk: { severity, value },
			// },
			applied_controls,
		}) => {
			const nestedIds = applied_controls?.map(({ id }) => id);
			const appliedControlNames = applied_controls?.map(
				(item) => item.client_control_catalog?.control_name
			);
			return {
				id,
				// scope,
				senario_name,
				investment_requirement,
				return_on_investment,
				total_cost_saving,
				// average_lose_exposure,
				// probability_of_occurrence,
				// severity,
				// nestedIds,
				// appliedControlNames,
			};
		}
	);

	// const filterSavedScenario =
    console.log(filteredData,'filtered');
	const filteredScenarioData = filterScenarioData(savedScenario?.investment_senario??[]);
	// Handler for saving the data
	const [saveScenario] = useMutation(SAVE_INVESTMENT_SCENARIO_DATA, {
		onCompleted: () => {
		  toast.success("It has been added successfully.");
		  fetchInvestmentAnalysisData();
		  setIsResultModalOpen(false);
		  refetchScenario();
		},
		onError: (err) => {
		  toast.error("Something went wrong, please try again.");
		},
	  });
	
	  const saveDataGql = () => {
		// Validation checks
		if (savedScenario?.length > 4) {
		  toast.error("You have reached the maximum amount of scenarios saved. Please delete a scenario to add a new one.");
		  return;
		}
	
		if (scenarioName.trim() === "") {
		  toast.error("Please enter a scenario name.");
		  return;
		}
	
		// Prepare the input object that conforms to the investment_senarioInputType
		const inputs = [{
			created_at: new Date().toISOString(),
			client_pk:1,
			business_process_pk:parseInt(processId),
		    senario_name: scenarioName,
		//   actual: investmentResult?.actual,
		//   new: investmentResult?.new,
			total_cost_saving: investmentResult?.total_cost_saving,
			investment_requirement: investmentResult?.investment_requirement,
			return_on_investment: investmentResult?.return_on_investment,
		//   applied_controls: investmentResult?.applied_controls || [],
		}];
	
		// Execute the mutation with the inputs object
		saveScenario({
		  variables: {
			inputs,
		  },
		});
	  };
	
	// Handler for saving the data
	const savedScenarioCard = (items) =>
		items?.map((item) => (
			<div
				key={item.id}
				className="bg-white rounded-lg shadow-lg p-4 flex flex-col items-center"
			>
				<div className="flex items-center mb-4">
					<div>
						<div className=" flex justify-between items-center ">
							<div className="flex justify-start">
								<button
									onClick={() => {
										handleListSelection(item?.nestedIds);
										scrollToTable();
									}}
								>
									<span className="font-bold text-xl text-gray-500 ui-monospace hover:text-blue-600">
										{item?.senario_name} {item.id}
									</span>
								</button>
							</div>

              <div className="flex justify-end items-center space-x-2">
                <ScenarioCardComponent
                  key={item.id}
                  item={item}
                  isChecked={!!checkedItems[item.id]}
                  onCheckboxChange={handleCheckboxChange}
                  onCompare={handleComparison}
                />{' '}
                <button className=" btn-delete justify-end">
                  <DeleteIcon
                    onClick={() => {
                    //   setRecordId(item.id);
                    //   toggleDeleteModal();
                    }}
                  />
                </button>
              </div>
            </div>
            <p className="text-gray-700 text-base">
              Scope: <span className="font-semibold">{item?.scope}</span>
            </p>
            <p className="text-gray-700  text-sm">
              Residual Risk:{'  '}
              <span
                className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                style={{
                //   backgroundColor: getBackgroundColor(item?.severity),
                  color: 'black',
                }}
              >
                {item?.severity}
              </span>{' '}
            </p>
            <p className="text-gray-700  text-sm">
              Avg. Loss Exposure:
              <span className="font-semibold">
                {item?.average_lose_exposure?.toFixed(0)}
              </span>
            </p>
            <p className="text-gray-700  text-sm">
              Probability of Occurrence :{' '}
              <span className="font-semibold">
                {item?.probability_of_occurrence}
              </span>
            </p>
            <p className="text-gray-700 text-sm">
              Cost Saving :{' '}
              <span className="font-semibold">
                {item?.total_cost_saving?.toFixed(0)}
              </span>
            </p>
            <p className="text-gray-700 text-sm">
              Investment Requirement:{' '}
              <span className="font-semibold">
                {item.investment_requirement?.toFixed(0)}
              </span>
            </p>{' '}
            <p className="text-gray-700 text-sm">
              Return on Investment:{'  '}
              <span className="font-semibold">
                {item?.return_on_investment}
              </span>
            </p>
            <p className="text-gray-700 text-sm">
              Applied controls for this Scenario :{'  '}
              <span className="font-semibold">{item.appliedControlNames}</span>
            </p>
          </div>
        </div>
      </div>
    ));

  useEffect(() => {
    fetchInvestmentAnalysisData();
  }, [selectedBusinessProcessId]);

	// Handle the edit click for investment
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};
	const [deleteInvestmentScenario] = useMutation(UPDATE_INVESTMENT_SCENARIO);

	const handleDelete = async (itemId) => {
		setFetchLoading(true);
		try {
	
			const response = await deleteInvestmentScenario({
				variables: { id: parseInt(itemId) },
			});
	
			const { affected_rows } = response.data.delete_investment_senario;
	
			if (affected_rows > 0) {
				refetchScenario(); 
				// Refetch the updated data after successful deletion
				toast.success("It has been deleted successfully");
			} else {
				toast.errorl("No rows were affected. Deletion failed.");
			}
		} catch (error) {
			// Handle errors such as GraphQL or network issues
			console.error("Failed to delete item:", error);
			toast.error("Something went wrong. Please try again.");
		} finally {
			setFetchLoading(false); // Ensure loading state is reset after the process
		}
	};

	return (
		<div className="p-10 md:p-4">
			<div className="p-4 space-y-4 md:space-y-6">
				<div className="h-full rounded-xl shadow-lg   dark:bg-gray-900">
					<div className="table-title ">
						<div className="flex justify-between items-center space-x-4">
							<div className="flex justify-between items-center space-x-4">
								<svg
									onClick={() => toggleFilters()}
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									stroke="currentColor"
									class="w-10 hover:bg-transparent h-10 text-white cursor-pointer"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
									/>
								</svg>
							</div>{" "}
							{showFilters && (
								<div
									onMouseLeave={() => {
										toggleFilters();
									}}
									className={`absolute text-white z-50 bg rounded-md p-2 mt-24 `}
								>
									<div>
										<SearchInput
											searchValue={searchValue}
											setSearchValue={handleInputChange}
										/>
									</div>
								</div>
							)}
							<span>Investment Analysis </span>
							<div className="relative flex justify-center items-center p-1 space-x-2 rounded-md">
								<div className="flex justify-end items-end w-56">
									{/* <label>Select application/process</label> */}
									<select
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
										id={selectedBusinessProcessId}
										onChange={(e) => {
											handleSelectionChange(e);
										}}
										value={selectedBusinessProcessId}
									>
										<option
											key="Select application/process"
											value="Select application/process"
										>
											Select application/process{" "}
										</option>
										{data?.business_process?.map((item) => (
											<option key={item.id} value={item.id}>
												{item.name}
											</option>
										))}
									</select>
								</div>
							</div>{" "}
						</div>

						{/* {InvestmentAnalysisData && InvestmentAnalysisData ? ( */}
							<div className="flex justify-between items-center space-x-4">
								<button
									onClick={handleRunInvestmentAnalysisClick}
									className="btn-add-new"
								>
									Run Investment Analysis
								</button>
							</div>
					</div>

					{loading ? (
						<LoadingComponent />
					) : filteredData ? (
						<>
							{/* <div className="mb-4">
								<CustomTable
									filteredData={filteredData}
									dataPerPage={5}
									onEditClick={handleEdit}
									onCheckboxChange={handleImplementedCheckboxChange}
									checkedItems={checkedImplementedItems}
									addManageEditColumn={true}
									addManageColumn={false}
								/>
							</div> */}
							<div className=" overflow-auto  ">
								<div className="text-black">
									<div className="">
										<table
											className={`tables table-stripe relative z-30 `}
										>
											<thead className="sticky-header ">
												<tr>
													<th scope="col">Control Name</th>
													<th scope="col">Control Domain</th>
													<th scope="col">Control Category</th>
													<th scope="col">Control Type</th>
													<th scope="col">Reduction Percentage(%)</th>
													<th scope="col">Investment</th>
													<th scope="col">Implement</th>
													<th scope="col">Manage</th>
												</tr>
											</thead>
											{!loading && <tbody>{DisplayData}</tbody>}
										</table>
										{loading ? <TableLoadingAnimation /> : null}
									</div>
								</div>
							</div>							<br />
							{true ? (
								<div className=" bg-gray-100 p-4 rounded-2xl  shadow-xl ">
									<div className="grid sm:grid-cols-1 md:grid-cols-2">
										<h3 className="font-bold text mb-4">
											Saved Investment Analysis
										</h3>
									</div>
									<div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 max-h-96 overflow-auto">
										{savedScenarioCard(savedScenario?.investment_senario)}
									</div>
								</div>
							) : null}
						</>
					) : isNullData ? (
						<div className="alert-no-data" role="alert">
							There is no data for selected business.
						</div>
					) : null}
				</div>
			</div>
			{/* comparison modal */}
			<Modal
				isOpen={isComparisonModalOpen}
				onClose={handleComparisonModal}
				size={"lg"}
			>
				<Modal.Header>
					<Modal.Title>Scenario</Modal.Title>
				</Modal.Header>
				{fetchLoading ? (
					<LoadingComponent />
				) : (
					<Modal.Body>
						<ComparisonCard
							// actual={actual}
							scenarios={scenarios}
							setScenarios={setScenarios}
						/>
					</Modal.Body>
				)}
				<Modal.Footer>
					<button
						className="btn-cancel"
						onClick={() => {
							setComparisonModalOpen(false);
						}}
					>
						Cancel
					</button>
				</Modal.Footer>
			</Modal>

			{/* add and edit modal */}
			<ModalHandler
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				mode={mode}
				size="lg"
				header="Investment analysis"
				selectedItemId={id}
				selectedItem={selectedItem}
				url_get={"url_get"}
				url_by_pk={"/threat-catalog/investment-analysis/"}
				fetchData={refetch}
				selectedClientId={selectedClientId}
				selectedBusinessProcessId={selectedBusinessProcessId}
			/>

			<Modal isOpen={isResultModalOpen} className="mt-1">
				<Modal.Header>
					<Modal.Title>Investment Analysis</Modal.Title>
				</Modal.Header>
				<Modal.Body className="modal-body-small">
					<div className="container mx-auto">
						<div className="grid grid-cols-3 gap-4">
							<div className="font-bold text-center">Category</div>
							<div className="font-bold text-center">Actual</div>
							<div className="font-bold text-center">New</div>
						</div>
						<div className="grid grid-cols-3 gap-4 mt-2">
							<div className="font-semibold mr-2">Scope</div>
							<div>{investmentResult?.actual?.scope}</div>
							<div>{investmentResult?.new?.scope}</div>
						</div>
						<div className="grid grid-cols-3 gap-4 mt-2">
							<div className="font-semibold mr-2">Residual Risk</div>
							<div>{investmentResult?.actual?.residual_risk?.severity}</div>
							<div>{investmentResult?.new?.residual_risk?.severity}</div>
						</div>
						<div className="grid grid-cols-3 gap-4 mt-2">
							<div className="font-semibold mr-2">
								Probability of Occurrence
							</div>
							<div> {investmentResult?.actual?.probability_of_occurrence}</div>
							<div> {investmentResult?.new?.probability_of_occurrence}</div>
						</div>
						<div className="grid grid-cols-3 gap-4 mt-2 border-b">
							<div className="font-semibold mr-2">Average Lose Exposure</div>
							<div> {investmentResult?.actual?.average_lose_exposure}</div>
							<div> {investmentResult?.new?.average_lose_exposure}</div>
						</div>
						<div className="grid grid-cols-3 gap-4 mt-4">
							<div className="font-semibold mr-2">Total Cost Saving</div>
							<div className="row-span-2 flex justify-center">
								{investmentResult.total_cost_saving}
							</div>
						</div>
						<div className="grid grid-cols-3 gap-4 ">
							<div className="font-semibold mr-2">Investment Requirement</div>
							<div className="row-span-2 flex justify-center">
								{investmentResult.investment_requirement}
							</div>
						</div>
						<div className="grid grid-cols-3 gap-4">
							<div className="row-span-1 font-semibold mr-2">
								Return on Investment
							</div>
							<div className="row-span-2 flex justify-center">
								{investmentResult.return_on_investment}
							</div>
						</div>
					</div>
					<div className="flex justify-between items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
						<div className="flex justify-center items-center space-x-4">
							<div>
								<label className="text-gray-400 italic">
									Save Scenario...optional
								</label>
								<input
									className="form-control w-64"
									type="text"
									name="investment"
									value={scenarioName}
									onChange={(e) => setScenarioName(e.target.value)}
									placeholder="Enter scenario name..."
								/>
							</div>
							<button
								// disabled={file ? false : true}
								onClick={() => saveDataGql()}
								// onClick={() => saveData()}
								data-modal-hide="defaultModal"
								type="button"
								className="btn-add-new mt-5 border  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
							>
								Save Scenario
							</button>
						</div>
						<button
							className="btn-cancel mt-5"
							onClick={() => {
								setIsResultModalOpen(false);
								setCheckedImplementedItems({});
							}}
						>
							Cancel
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default InvestmentAnalysis;
