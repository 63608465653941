import React, { useState } from "react";
import {
	VictoryBar,
	VictoryChart,
	VictoryAxis,
	VictoryTooltip,
	VictoryTheme,
} from "victory";
import Modal from "../../../shared/Modal";

const DomainThreatChart = ({ data }) => {
	const [selectedDomain, setSelectedDomain] = useState(null);
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const chartData = data?.map((item) => ({
		domain: item.control_domain,
		threatsCount: item.threat_count?.length,
	}));

	const truncateText = (text, length) => {
		return text.length > length ? text.substring(0, length) + "..." : text;
	};

	const handleDomainClick = (domainData) => {
		if (selectedDomain && selectedDomain.domain === domainData.domain) {
			setModalIsOpen(false);
			setSelectedDomain(null);
		} else {
			setSelectedDomain(domainData);
			setModalIsOpen(true);
		}
	};

	const closeModal = () => {
		setModalIsOpen(false);
		setSelectedDomain(null);
	};
	return (
		<>
			<VictoryChart
				theme={VictoryTheme.material}
				domainPadding={30}
				height={375}
				width={700}
			>
				<VictoryAxis
					tickValues={chartData?.map((_, index) => index + 1)}
					// tickFormat={chartData.map((item) => truncateText(item.domain, 30))}
					tickFormat={chartData?.map((item) => item.domain)}
					style={{
						axis: { stroke: "#756f6a" },
						ticks: { stroke: "#756f6a" },
						tickLabels: {
							fontSize: 10,
							padding: 5,
							angle: 15,
							textAnchor: "start",
							fill: "#4a4a4a",
						},
					}}
				/>
				<VictoryAxis
					dependentAxis
					style={{
						axis: { stroke: "#756f6a" },
						ticks: { stroke: "#756f6a" },
						tickLabels: { fontSize: 12, padding: 5, fill: "#4a4a4a" },
					}}
				/>
				<VictoryBar
					data={chartData}
					x="domain"
					y="threatsCount"
					barWidth={50}
					labels={({ datum }) =>
						`${datum.threatsCount} ${
							datum.threatsCount === 1 ? "threat" : "threats"
						}`
					}
					labelComponent={
						<VictoryTooltip
							flyoutStyle={{
								width: 90,
								stroke: "#375e87",
								strokeWidth: 2,
							}}
						/>
					}
					style={{
						data: {
							fill: "var(--color-cronus-primary)",
							// stroke: "#375e87",
							// strokeWidth: 2,
							// opacity: [0.9],
						},
						labels: { fontSize: 20, fill: "#" },
					}}
					events={[
						{
							target: "data",
							eventHandlers: {
								onClick: (event, props) => {
									handleDomainClick(data[props.index]);
								},
							},
						},
					]}
				/>
			</VictoryChart>
			<Modal isOpen={modalIsOpen} onClose={closeModal} size="sm">
				<Modal.Header>
					<Modal.Title>
						{`Possible Threat for ${selectedDomain?.control_domain}`}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ul className="list-disc list-inside ">
						{selectedDomain?.threat_count.length === 0 ? (
							<li>No threats</li>
						) : (
							selectedDomain?.threat_count.map((threat, index) => (
								<li key={index}>{threat}</li>
							))
						)}
					</ul>
				</Modal.Body>
				<div className="flex p-4 justify-end">
					<button className=" btn-cancel p-2" onClick={closeModal}>
						X
					</button>
				</div>
			</Modal>
		</>
	);
};

export default DomainThreatChart;
