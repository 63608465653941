import { gql } from "@apollo/client";
export const GET_BUSINESS_PROCESS = gql`
  query GET_BUSINESS_PROCESS($limit: Int!, $offset: Int) {
    business_process(limit: $limit, offset: $offset) {
      id
      name
      revenue_contribution
      max_sensitive_records
      compliance_requirements
      sensitivity_level
      data_classification_level {
        label
      }
      business_criticality_rpo {
        label
      }
      business_impact {
        label
      }
      data_source {
        label
      }
      user_interface_accessibility {
        label
      }
      user_broadest_population {
        label
      }
      user_population {
        label
      }
      application_accessibility {
        label
      }
      business_criticality_rpo {
        label
      }
      hosting_model {
        label
      }

      application_risk_value
      application_risk_profile {
        low
        medium
        high
      }
    }
    business_process_aggregate {
      count
    }
  }
`;

export const GET_EXECUTIVE_DASHBOARD_ANALYSIS = gql`
  query GET_EXECUTIVE_DASHBOARD_ANALYSIS {
    executive_dashboard_analysis {
      loss_exposure
      total_investment
      revenue_contribution
      business_process_summary {
        classification_level
        compliance_requirements
      }
      top_five_threats {
        name
        threat_severity {
          low
          medium
          high
        }
      }
    }
  }
`;
export const GET_BUSINESS_IMPACT_ANALYSIS = gql`
  query GET_BUSINESS_IMPACT_ANALYSIS($business_process_id: ID!) {
    business_impact_analysis(business_process_id: $business_process_id) {
      revenue_contribution
      key_business_impact
      estimated_business_losses
      criticality
      regulatory_requirements
      overall_risk_rating
    }

    client_asset_aggregate {
      count
    }
  }
`;
export const GET_VELNERABILITY_SUMMARY = gql`
  query GET_VELNERABILITY_SUMMARY($cpe: String!) {
    cpe_summary(cpe: $cpe) {
      data
    }
  }
`;
export const GET_THREAT_PROFILING = gql`
  query GET_THREAT_PROFILING($business_process_id: ID!) {
    threat_profiling_analysis(business_process_id: $business_process_id) {
      frequency_of_occurrence
      breach_count
      incident_count
      incident_response_distribution {
        lost_business_cost
        detection_and_escalation
        ex_post_response
        notification
      }
      breach_response_distribution {
        lost_business_cost
        detection_and_escalation
        ex_post_response
        notification
      }
      incident_cost
      breach_cost
    }
  }
`;
export const GET_CONTROL_ANALYSIS = gql`
  query GET_CONTROL_ANALYSIS($limit: Int!, $offset: Int) {
    control_analysis(limit: $limit, offset: $offset) {
      id
      client {
        id
        name
      }
      business_process {
        name
      }
      risk_profile {
        low
        medium
        high
      }
      control {
        name
        effectiveness
        relevance
        control_type {
          label
        }
        control_domain {
          label
        }
        control_category {
          label
        }
        primary_threat {
          name
        }
        secondary_threats {
          name
        }
      }
      is_applicable_by_admin
      is_implemented_by_admin
      is_applicable
      is_implemented
    }
  }
`;
export const GET_CLIENT_BIA = gql`
  query GET_CLIENT_BIA($limit: Int!, $offset: Int) {
    client_asset(limit: $limit, offset: $offset) {
      id
      name
      cpe
      version
      vendor
      risk
    }
  }
`;

export const UPDATE_CONTROL_ANALYSIS_MUTATION = gql`
  mutation UpdateControlAnalysis(
    $id: Int!
    $isApplicable: Boolean
    $isImplemented: Boolean
    $isApplicableByAdmin: Boolean
    $isImplementedByAdmin: Boolean
  ) {
    update_control_analysis(
      where: { id: { exact: $id } }
      input: {
        is_applicable: $isApplicable
        is_implemented: $isImplemented
        is_applicable_by_admin: $isApplicableByAdmin
        is_implemented_by_admin: $isImplementedByAdmin
      }
    ) {
      affected_rows
    }
  }
`;
export const UPDATE_CLIENT_BIA = gql`
  mutation UPDATE_CLIENT_BIA(
    $id: Int!
    $input: client_assetInputType!
  ) {
    update_client_asset(
      where: { id: { exact: $id } }
      input: $input
    ) {
      affected_rows
    }
  }
`;

export const GET_EXPLOITABLE_PATH_QUERY = gql`
  query GetExploitablePath {
    exploitable_path(
      where: {
        entry: { name: { isnull: false } }
        middle: { name: { isnull: false } }
        end: { name: { isnull: false } }
      }
    ) {
      entry {
        name
      }
      middle {
        name
      }
      end {
        name
      }
    }
  }
`;

export const GET_INVESTMENT_ANALYSIS = gql`
  query GetInvestmentAnalysis {
    investment_analysis(limit: 100) {
      id
      business_process {
        id
        name
      }
      control {
        name
        control_type {
          label
        }
        control_domain {
          label
        }
        control_category {
          label
        }
      }
      investment
      reduction_percentage
    }
  }
`;

export const GET_INVESTMENT_ANALYSIS_RESULT = gql`
  query InvestmentAnalysisResult(
    $businessProcessId: ID!
    $appliedControls: [ID!]!
  ) {
    investment_analysis_result(
      business_process_id: $businessProcessId
      applied_controls: $appliedControls
    ) {
      actual {
        scope
        residual_risk_value
        probability_of_occurrence
        average_lose_exposure
      }
      new {
        scope
        residual_risk_value
        probability_of_occurrence
        average_lose_exposure
      }
      total_cost_saving
      investment_requirement
      return_on_investment
    }
  }
`;
export const SAVE_INVESTMENT_SCENARIO_DATA = gql`
  mutation SaveInvestmentScenario($inputs: [investment_senarioInputType]!) {
    create_investment_senario(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const GET_INVESTMENT_SCENARIOS = gql`
  query GetInvestmentScenarios($limit: Int!) {
    investment_senario(limit: $limit) {
      id
      senario_name
      total_cost_saving
      investment_requirement
      return_on_investment
      actual
      new
    }
  }
`;

export const UPDATE_INVESTMENT_ANALYSIS = gql`
  mutation UPDATE_INVESTMENT_ANALYSIS(
    $id: Int!
    $input: investment_analysisInputType!
  ) {
    update_investment_analysis(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const UPDATE_INVESTMENT_SCENARIO = gql`
  mutation UPDATE_INVESTMENT_SCENARIO($id: Int!) {
    delete_investment_senario(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;
export const DELETE_BUSINESS_PROCESS = gql`
  mutation DELETE_BUSINESS_PROCESS($id: Int!) {
    delete_business_process(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;
export const DELETE_CLIENT_ASSET = gql`
  mutation DELETE_CLIENT_ASSET($id: Int!) {
    delete_client_asset(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;
export const CREATE_CLIENT_ASSET = gql`
  mutation CreateClientAsset($inputs: [client_assetInputType]!) {
    create_client_asset(inputs: $inputs) {
      affected_rows
    }
  }
`;
