import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import http from "../resources/http";
import { baseURL } from "../resources/apiClient";
import swal from "sweetalert";
import { downloadBusinessProcess } from "../utils/ExportExcelUtility";
import urls from "../resources/urls";
import { complianceRequirementsOptions, sensetivities } from "../data/data";
import { fieldNames } from "../data/businessProcessFields";
import {
	actionTypes,
	businessProcessReducer,
	initialState,
} from "../hooks/BusinessProcess/reducers/reducers";
import { Star } from "@mui/icons-material";
import { name, pid } from "../authentication/store/actions";

import Modal from "../shared/Modal";
import { DeleteModal } from "../shared/DeleteModal";

import MultiSelect from "../shared/MultiSelect";
import MultipleSelectCheckmarks from "../shared/MultiSelect/MultiSelect";
import TableLoadingAnimation from "../shared/TableLoading/TableLoadingAnimation";
import {
	getApplicationRiskProfilColor,
	getBackgroundColor,
} from "../utils/getBackgroundColor";
import { calculatePercentageReduction } from "../utils/helpers";
import ReactPaginate from "react-paginate";
import { useMutation, useQuery } from "@apollo/client";
import {
	DELETE_BUSINESS_PROCESS,
	GET_BUSINESS_PROCESS,
} from "../graphql/client/query";
import {
	GET_AAPLICATION_ACCESSIBILITY,
	GET_BROADCAST_POPULATION_OF_USERS,
	GET_BUSINESS_CRITICALITY,
	GET_BUSINESS_IMPACT,
	GET_DATA_CLASSIFICATION,
	GET_DATA_SOURCE,
	GET_HOSTING_MODEL,
	GET_USER_INTERFACE,
	GET_USER_POPULATION,
} from "../graphql/superAdmin/query/businessProcessConfiguration/query";
import { ADD_BUSINESS_PROCESS } from "../graphql/superAdmin/mutation/businessProcessConfiguration/mutation";
import toast from "react-hot-toast";
// import Form from "../shared/Form";
import ModalFileUpload from "../shared/ModalFileUpload";
import { UPLOAD_THREAT_CATALOG_THREAT_COUNTS } from "../api/excelUpload";

const ManageBusinessProcessAdmin = ({ isPopUp }) => {
	const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);
	const UploadURL = UPLOAD_THREAT_CATALOG_THREAT_COUNTS;
	const [selectedBPId, setSelectedBPId] = useState(0);
	const [isModalOpen, setModalOpen] = useState(false);
	const [showHideDelete, setSetShowHideDelete] = useState(false);
	const [isModalEditOpen, setModalEditOpen] = useState(false);

	const [datas, setData] = useState([]);
	const [clients, setClients] = useState([]);
	const [val, setVal] = useState();
	const [showHide, setSetShowHide] = useState(false);

	const [showHideMe, setSetShowHideMe] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const toggleDeleteModal = () => setDeleteModal(!deleteModal);

	// const handleModalShowHideDelete = () => setSetShowHideDelete(!showHideDelete);

	const [selected, setSelected] = useState([]);

	const complianceRequirementsList = selected?.map((items) => items.value);
	const [clientId, setClientId] = useState("");

	const dispatchProcess = useDispatch();

	const [state, dispatch] = useReducer(businessProcessReducer, initialState);

	//api date receivers
	// page pagination
	const [pageNumber, setPageNumber] = useState(0);
	// const dataPerPage = 7;
	const pagesVisited = pageNumber * 4;

	// Use filteredData instead of data for page count
	const pageCount = Math.ceil(state?.businessProcessList?.length / 4);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};

	let localStorageUserData = JSON.parse(localStorage.getItem("cyber-minds"));
	const clientID = localStorageUserData?.user?.client?.id;
	const sensetivityValue =
		localStorageUserData?.user?.favorite_business_process?.sensitivity_value;

	// const clientId = localStorageUserData?.user?.client?.id;
	const [errors, setErrors] = useState([]);
	const [businessProcess, setBusinessProcess] = useState({});
	const [business_processe, setBusinessProcesse] = useState(0);
	const [selectedItems, setSelectedItems] = useState([]);
	const [
		preSelectedComplianceRequireentList,
		setPreSelectedComplianceRequireentList,
	] = useState([]);

	const [complianceList, setComplianceList] = useState([]);
	const handleComplianceListOptionsChange = (selected) => {
		setComplianceList(selected);
	};
	//Get process ID from redux store
	const processId = useSelector((state) => state.pid);
	// Function to check if a value is empty
	const isEmpty = (value) => {
		return value === "" || value === null || value === undefined;
	};

	// const { data: userInterfaceData } = useQuery(GET_USER_INTERFACE, {
	//   variables: { limit: 100 },
	// });

	// console.log(userInterfaceData,'userData')
	const {
		data: accessibilityData,
		loading: accessibilityLoading,
		error: accessibilityError,
	} = useQuery(GET_AAPLICATION_ACCESSIBILITY, {
		variables: { limit: 10, offset: 0 },
	});
	const {
		data: broadcastData,
		loading: broadcastLoading,
		error: broadcastError,
	} = useQuery(GET_BROADCAST_POPULATION_OF_USERS, {
		variables: { limit: 10, offset: 0 },
	});
	const {
		data: criticalityData,
		loading: criticalityLoading,
		error: criticalityError,
	} = useQuery(GET_BUSINESS_CRITICALITY, {
		variables: { limit: 10, offset: 0 },
	});
	const {
		data: impactData,
		loading: impactLoading,
		error: impactError,
	} = useQuery(GET_BUSINESS_IMPACT, {
		variables: { limit: 10, offset: 0 },
	});
	const {
		data: classificationData,
		loading: classificationLoading,
		error: classificationError,
	} = useQuery(GET_DATA_CLASSIFICATION, {
		variables: { limit: 10, offset: 0 },
	});
	const {
		data: hostingData,
		loading: hostingLoading,
		error: hostingError,
	} = useQuery(GET_HOSTING_MODEL, {
		variables: { limit: 10, offset: 0 },
	});
	const {
		data: dataSourceData,
		loading: dataSourceLoading,
		error: dataSourceError,
	} = useQuery(GET_DATA_SOURCE, {
		variables: { limit: 10, offset: 0 },
	});
	const {
		data: interfaceData,
		loading: interfaceLoading,
		error: interfaceError,
	} = useQuery(GET_USER_INTERFACE, {
		variables: { limit: 10, offset: 0 },
	});
	const {
		data: userPopulationData,
		loading: userPopulationLoading,
		error: userPopulationError,
	} = useQuery(GET_USER_POPULATION, {
		variables: { limit: 10, offset: 0 },
	});

	const { data, loading, refetch } = useQuery(GET_BUSINESS_PROCESS, {
		variables: { limit: 100 },
	});
	// Function to determine if a field is required or not
	const isRequired = (field) => {
		// Implement your own logic here to determine if the field is required
		// For example, you can check if the field name is 'complianceRequirement' and return true or false accordingly
		if (field === "complianceRequirement") {
			return false; // Return false if 'complianceRequirement' is optional
		} else {
			return true; // Return true for other fields that are required
		}
	};

	// Function to validate form fields
	const validate = () => {
		const newErrors = {};

		// Loop through field names and check for empty values in businessProcess object
		fieldNames.forEach((field) => {
			// Skip validation for 'complianceRequirement' field if it is not required
			if (field === "complianceRequirement" && !isRequired(field)) {
				return;
			}

			if (isEmpty(businessProcess[field])) {
				newErrors[field] = "This field is required";
			}
		});

		// Update errors state with newErrors object
		setErrors(newErrors);

		// Return newErrors object
		return newErrors;
	};

	// Function to update field values in businessProcess object
	const setField = (field, value) => {
		setBusinessProcess((prevBusinessProcess) => ({
			...prevBusinessProcess,
			[field]: value,
		}));
		if (!!errors[field]) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[field]: null,
			}));
		}
	};
	const updateMyAccount = (businessProcessId) => {
		http
			.patch(`${baseURL}/account/update-my-account`, {
				favorite_business_process: businessProcessId,
			})
			.then((response) => {
				fetchData();
			})
			.catch((err) => {
				swal(err);
			});
	};
	const clearAll = () => {
		setBusinessProcess({});
	};
	const fetchData = () => {
		Promise.all([
			http.get(`${baseURL}/${urls.businessProcess.getMyBusinessProcess}`),
			http.get(`${baseURL}/business_process/get-my-business-process`),
			http.get(`${baseURL}/${urls.businessProcess.dataSource}`),
			http.get(`${baseURL}/${urls.businessProcess.userInterfaceAccessibility}`),
			http.get(`${baseURL}/${urls.businessProcess.hostingModel}`),
			http.get(`${baseURL}/${urls.businessProcess.businessCriticality}`),
			http.get(`${baseURL}/${urls.businessProcess.dataClassificationLevel}`),
			http.get(`${baseURL}/${urls.businessProcess.broadestUserPopulation}`),
			http.get(`${baseURL}/${urls.businessProcess.userPopulation}`),
			http.get(`${baseURL}/${urls.businessProcess.applicationAccessibility}`),
			http.get(`${baseURL}/${urls.businessProcess.businessImpact}`),
		])
			.then((responses) => {
				dispatch({
					type: actionTypes.SET_CRITICALITIES,
					payload: responses[5].data,
				});
				dispatch({
					type: actionTypes.SET_BUSINESS_PROCESS_LIST,
					payload: responses[1].data,
				});
				dispatch({
					type: actionTypes.SET_CLASSIFICATIONS,
					payload: responses[6].data,
				});
				dispatch({
					type: actionTypes.SET_BROADCASTS,
					payload: responses[7].data,
				});
				dispatch({
					type: actionTypes.SET_MAX_USERS,
					payload: responses[8].data,
				});
				dispatch({
					type: actionTypes.SET_APP_ACCESSS,
					payload: responses[9].data,
				});
				dispatch({
					type: actionTypes.SET_HOSTING_MODELS,
					payload: responses[4].data,
				});
				dispatch({
					type: actionTypes.SET_USER_INTERFACES,
					payload: responses[3].data,
				});
				dispatch({ type: actionTypes.SET_SOURCES, payload: responses[2].data });
				dispatch({
					type: actionTypes.SET_BUSINESS_IMPACTS,
					payload: responses[10].data,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// useEffect(() => {
	//   fetchData();
	// }, []);
	const [id, setId] = useState(0);

	const handleDelete = async () => {
		try {
			const response = await http.delete(
				`${baseURL}/business_process/business-process/${id}`
			);
			swal("Success", "It has been deleted successfully.", "success");
			fetchData();
			toggleDeleteModal();
		} catch (err) {
			swal("Error", "Something went wrong, please try again.", "error");
		}
	};

	const [addBusinessProcess] = useMutation(ADD_BUSINESS_PROCESS);

	const submit = (e) => {
		e.preventDefault();
		const businessProcessErrors = validate();
		if (Object.keys(businessProcessErrors).length > 1) {
			setErrors(businessProcessErrors);
		} else {
			// Get the current date and format it
			const createdAt = new Date().toISOString().slice(0, 19).replace("T", " "); // Example format "YYYY-MM-DD HH:MM:SS"

			// Prepare `inputs` with parsed integers and the current date
			const inputs = [
				{
					created_at: createdAt,
					name: businessProcess.businessProcessName,
					revenue_contribution: parseInt(
						businessProcess.revenue_contribution,
						10
					),
					max_sensitive_records: parseInt(
						businessProcess.max_sensitive_records,
						10
					),
					sensitivity_level: businessProcess.risk_sensitivity,
					business_criticality_rpo_pk: parseInt(
						businessProcess.business_criticality_rpo,
						10
					),
					data_classification_level_pk: parseInt(
						businessProcess.data_classification_level,
						10
					),
					user_broadest_population_pk: parseInt(
						businessProcess.user_broadest_population,
						10
					),
					user_population_pk: parseInt(businessProcess.user_population, 10),
					application_accessibility_pk: parseInt(
						businessProcess.application_accessibility,
						10
					),
					hosting_model_pk: parseInt(businessProcess.hosting_model, 10),
					user_interface_accessibility_pk: parseInt(
						businessProcess.user_interface_accessibility,
						10
					),
					data_source_pk: parseInt(businessProcess.data_source, 10),
					business_impact_pk: parseInt(businessProcess.business_impact, 10),
					client_pk: parseInt(clientID, 10),
					// compliance_requirements: complianceList,
				},
			];

			// Execute GraphQL mutation
			addBusinessProcess({ variables: { inputs } })
				.then((response) => {
					if (response.data.create_business_process.affected_rows > 0) {
						setModalOpen(false);
						swal("Success", "It has been added successfully!", "success");
						refetch();
					}
				})
				.catch((err) => {
					swal("Error", "Something went wrong, please try again.", "error");
				});
		}
	};

	const update = () => {
		http
			.patch(`${baseURL}/business_process/business-process/${val}`, {
				client: clientID,
				name: businessProcess.businessProcessName,
				business_owner: businessProcess.business_owner,
				application_owner: businessProcess.application_owner,
				revenue_contribution: businessProcess.revenue_contribution,
				compliance_requirements: complianceList,
				max_sensitive_records: businessProcess.max_sensitive_records,
				business_criticality_rpo: businessProcess.business_criticality_rpo,
				data_classification_level: businessProcess.data_classification_level,
				user_broadest_population: businessProcess.user_broadest_population,
				user_population: businessProcess.user_population,
				application_accessibility: businessProcess.application_accessibility,
				hosting_model: businessProcess.hosting_model,
				user_interface_accessibility:
					businessProcess.user_interface_accessibility,
				data_source: businessProcess.data_source,
				business_impact: businessProcess.business_impact,
				sensitivity_value: businessProcess.risk_sensitivity,
			})
			.then(
				(response) => {
					setModalEditOpen(false);
					swal("Success", "It has been updated successfully.", "success");
					refetch();
				},
				(err) => {
					swal("Error", "Something went wrong, please try again.", "error");
				}
			);
	};
	const firstProcessId = processId
		? processId
		: state?.businessProcessList[0]?.id;
	const [activeItemId, setActiveItemId] = useState(null);

	const waitASecondToFetchFirstID = () => {
		setTimeout(function () {
			// A function to set the first business process ID after 1 second to avoid any fetching delay
			setActiveItemId(firstProcessId);
		}, 1000);
	};
	useEffect(() => {
		waitASecondToFetchFirstID();
	}, []);

	const handleItemClick = (id) => {
		setActiveItemId(id);
	};
	const [selectedItem, setSelectedItem] = useState(null);

	// Function to handle opening the edit modal with populated data
	const handleEditClick = (sector) => {
		setSelectedItem({
			id: sector?.id,
			name: sector?.name,
			business_owner: sector?.business_owner,
			application_owner: sector?.application_owner,
			revenue_contribution: sector?.revenue_contribution,
			max_sensitive_records: sector?.max_sensitive_records,
			business_criticality_rpo: sector?.business_criticality_rpo?.label,
			data_classification_level: sector?.data_classification_level?.label,
			user_broadest_population: sector?.user_broadest_population?.label,
			user_population: sector?.user_population?.label,
			application_accessibility: sector?.application_accessibility?.label,
			hosting_model: sector?.hosting_model?.label,
			user_interface_accessibility: sector?.user_interface_accessibility?.label,
			data_source: sector?.data_source?.label,
			business_impact: sector?.business_impact?.label,
			risk_sensitivity: sector?.sensitivity_level,
		});
	};
	const [deleteBusinessProcessMutation] = useMutation(DELETE_BUSINESS_PROCESS);

	const deleteBusinessProcess = async (id) => {
		try {
			const response = await deleteBusinessProcessMutation({
				variables: { id: parseInt(id, 10) },
			});
			if (response.data.delete_business_process.affected_rows > 0) {
				toast.success("Business process deleted successfully");
				refetch();
			} else {
				toast.error("No business process found with the provided id");
			}
		} catch (err) {
			console.error("Error deleting business process:", err);
		}
	};

	const processName = useSelector((state) => state.name);
	console.log(selectedItem, "selected items");
	console.log(data?.business_process, "data items");

	const DisplayData = data?.business_process
		?.slice(pagesVisited, pagesVisited + 4)
		?.map((sectors) => {
			return (
				<>
					<tr key={sectors.id}>
						<td>{sectors.name} </td>
						<td className="text-white font-semibold ">
							<p
								className="p-2 text-center rounded-xl w-36"
								style={{
									backgroundColor: getApplicationRiskProfilColor(
										sectors?.application_risk_value
									),
									color: "black",
								}}
							></p>
						</td>
						<td className="">
							<p
								className="p-4 text-center rounded-xl w-36"
								style={{
									backgroundColor: getBackgroundColor(
										sectors?.application_risk_profile?.low
									),
									color: "black",
								}}
							></p>
						</td>
						<td className="">
							<p>{calculatePercentageReduction(12, 20) + "%"}</p>
						</td>
						{!isPopUp ? (
							<td
								className="cursor-pointer"
								style={{
									color: sectors.id === activeItemId ? "#CE9F2C" : "white",
								}}
							>
								<Star
									onClick={() => {
										localStorage.setItem(
											"favoriteProcess",
											JSON.stringify({
												processName: sectors.name,
												processID: sectors.id,
											})
										);
										handleItemClick(sectors.id);
										updateMyAccount(sectors.id);
									}}
								/>
							</td>
						) : null}

						{true ? (
							<td>
								<IconButton onClick={() => handleEditClick(sectors)}>
									<EditIcon />
								</IconButton>
								<IconButton
									onClick={() => {
										deleteBusinessProcess(sectors.id);
									}}
								>
									<DeleteIcon />
								</IconButton>
							</td>
						) : null}
					</tr>
				</>
			);
		});

	useEffect(() => {
		const initialSelected = complianceRequirementsOptions?.filter((option) =>
			selectedItems.some(
				(selectedItem) => selectedItem?.value === option?.value
			)
		);
		setSelected(initialSelected);
	}, [complianceRequirementsOptions, selectedItems]);

	return (
		<div className="p-10">
			{deleteModal ? (
				<DeleteModal
					handleDelete={handleDelete}
					handleModal={toggleDeleteModal}
				/>
			) : null}
			<div className="table-title ">
				<div>
					<span onClick={() => updateMyAccount()} className="font-bold ">
						Business Processes | {processName}
					</span>
				</div>
				<div className="flex justify-between items-center space-x-4">
					<div className="flex justify-between items-center">
						<div className="flex justify-center space-x-4 items-center">
							<button
								// onClick={() => toggleFileUploader()}
								className="flex justify-center items-center btn_file_upload"
							>
								<img
									alt="upload file"
									className="w-7 h-7"
									src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
								/>
								Upload File
							</button>
							<div className="btn_file_download">
								<svg
									onClick={() =>
										downloadBusinessProcess(data, "Business Process")
									}
									xmlns="http://www.w3.org/2000/svg"
									height={28}
									width={28}
									fill-rule="evenodd"
									fill="white"
									clip-rule="evenodd"
									image-rendering="optimizeQuality"
									shape-rendering="geometricPrecision"
									text-rendering="geometricPrecision"
									viewBox="0 0 17639 17639"
								>
									<path
										fill="#1e7145"
										d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
									/>
									<path
										fill="#1e7145"
										d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
									/>
								</svg>
							</div>

							<button
								className="btn-add-new"
								onClick={(e) => {
									const localStorageUserData = JSON.parse(
										localStorage.getItem("cyber-minds")
									);
									setClientId(localStorageUserData?.user?.client?.id);
									setPreSelectedComplianceRequireentList([]);
									clearAll();
									setModalOpen(true);
								}}
							>
								<AddIcon className="bold" />
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className=" ">
				<table className="tables">
					<thead className="">
						<tr>
							<th>Business process name</th>
							<th>Application Risk Profile</th>
							<th>Residual Risk</th>
							<th>Percentage reduction</th>
							{!isPopUp ? <th>Favorite</th> : null}
							{!isPopUp ? <th>Manage</th> : null}
						</tr>
					</thead>
					<tbody>{DisplayData}</tbody>
				</table>
				{loading ? <TableLoadingAnimation /> : null}
				<div className="paginationBttns">
					{state?.businessProcessList?.length > 4 ? (
						<ReactPaginate
							previousLabel={"<"}
							nextLabel={">"}
							activeClassName={"paginationActive"}
							pageCount={pageCount}
							onPageChange={changePage}
							containerClassName={"paginationBttns"}
							previousLinkClassName={"previousBttn"}
							nextLinkClassName={"nextBttn"}
							disabledClassName={"paginationDisabled"}
						/>
					) : null}{" "}
				</div>
			</div>

			<Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} size="xl">
				<Modal.Header>
					<Modal.Title>Add New Business Processs Test</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="">
						<Form.Group>
							<Form.Label for="email">Business Process Name</Form.Label>
							<Form.Control
								type="text"
								id="text"
								value={businessProcess.businessProcessName}
								onChange={(e) =>
									setField("businessProcessName", e.target.value)
								}
								placeholder="Text..."
								required
								isInvalid={!!errors.businessProcessName}
								name="name"
							></Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.businessProcessName}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">Business Owner</Form.Label>
							<Form.Control
								type="text"
								id="text"
								value={businessProcess.business_owner}
								onChange={(e) => setField("business_owner", e.target.value)}
								placeholder="Text..."
								required
								name="name"
								isInvalid={!!errors.businessProcessName}
							></Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.business_owner}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">Application Owner</Form.Label>
							<Form.Control
								type="text"
								id="text"
								value={businessProcess.application_owner}
								onChange={(e) => setField("application_owner", e.target.value)}
								placeholder="Text..."
								required
								name="name"
								isInvalid={!!errors.application_owner}
							></Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.application_owner}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">Risk Sensitivity</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.risk_sensitivity}
								onChange={(e) => setField("risk_sensitivity", e.target.value)}
								isInvalid={!!errors.risk_sensitivity}
							>
								<option selected value={sensetivityValue}>
									{sensetivityValue}
								</option>
								{sensetivities
									?.filter((item) => item.name !== sensetivityValue)
									?.map((x, y) => (
										<option value={x.name} key={y}>
											{x.name}
										</option>
									))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.risk_sensitivity}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">Revenue Contribution in USD</Form.Label>
							<Form.Control
								type="number"
								min={0}
								id="text"
								value={businessProcess.revenue_contribution}
								onChange={(e) =>
									setField("revenue_contribution", e.target.value)
								}
								placeholder="Enter value"
								required
								name="revenue_contribution"
								isInvalid={!!errors.revenue_contribution}
							></Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.revenue_contribution}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">Compliance Requirementsss</Form.Label>
							{/* <MultiSelect
              options={complianceRequirementsOptions}
              selectedValues={selected}
              onSelectionChange={setSelected}
            /> */}
							<MultipleSelectCheckmarks
								data={complianceRequirementsOptions}
								onChange={handleComplianceListOptionsChange}
								tag="Select Compliance Requirements"
								initialSelectedValues={preSelectedComplianceRequireentList}
							/>
							<Form.Control.Feedback type="invalid"></Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">
								Number of max sensitive records managed/processed by the
								application?
							</Form.Label>
							<Form.Control
								type="number"
								min={0}
								id="text"
								placeholder="Enter value"
								value={businessProcess.max_sensitive_records}
								onChange={(e) =>
									setField("max_sensitive_records", e.target.value)
								}
								required
								name="name"
								isInvalid={!!errors.max_sensitive_records}
							></Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.max_sensitive_records}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">Business Criticality (RPO)</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.business_criticality_rpo}
								onChange={(e) =>
									setField("business_criticality_rpo", e.target.value)
								}
								// value={riskATC.threat}
								// onChange={(e) => setField("threat", e.target.value)}
								isInvalid={!!errors.business_criticality_rpo}
							>
								<option value="">Select... </option>
								{criticalityData?.business_criticality_rpo?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.business_criticality_rpo}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group>
							<Form.Label for="email">
								What is the highest Classification Level of the data
								held/processed by the system?
							</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.data_classification_level}
								onChange={(e) =>
									setField("data_classification_level", e.target.value)
								}
								isInvalid={!!errors.data_classification_level}
							>
								<option>Select...</option>
								{classificationData?.data_classification_level?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.data_classification_level}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group>
							<Form.Label for="email">
								What is the broadest population of users of this application?
							</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.user_broadest_population}
								onChange={(e) =>
									setField("user_broadest_population", e.target.value)
								}
								isInvalid={!!errors.user_broadest_population}
							>
								<option value="">Select... </option>
								{broadcastData?.user_broadest_population?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.user_broadest_population}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group>
							<Form.Label for="email">
								What is the max. user population?
							</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.user_population}
								onChange={(e) => setField("user_population", e.target.value)}
								// value={riskATC.threat}
								// onChange={(e) => setField("threat", e.target.value)}
								isInvalid={!!errors.user_population}
							>
								<option value="">Select... </option>
								{userPopulationData?.user_population?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.user_population}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group>
							<Form.Label for="email">
								How will the application be predominantly accessed?{" "}
							</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.application_accessibility}
								onChange={(e) =>
									setField("application_accessibility", e.target.value)
								}
								isInvalid={!!errors.application_accessibility}
							>
								<option value="">Select... </option>
								{accessibilityData?.application_accessibility?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.application_accessibility}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group>
							<Form.Label for="email">Hosting Model</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.hosting_model}
								onChange={(e) => setField("hosting_model", e.target.value)}
								isInvalid={!!errors.hosting_model}
							>
								<option value="">Select... </option>
								{hostingData?.hosting_model?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.hosting_model}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group>
							<Form.Label for="email">
								What is the most accessible user interface for this application?
							</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.user_interface_accessibility}
								onChange={(e) =>
									setField("user_interface_accessibility", e.target.value)
								}
								isInvalid={!!errors.user_interface_accessibility}
							>
								<option value="">Select... </option>
								{interfaceData?.user_interface_accessibility?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.user_interface_accessibility}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">
								What is the source/destination of data within the application?{" "}
							</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.data_source}
								onChange={(e) => setField("data_source", e.target.value)}
								isInvalid={!!errors.data_source}
							>
								<option value="">Select... </option>
								{dataSourceData?.data_source?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.data_source}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">Business Impact</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.business_impact}
								onChange={(e) => setField("business_impact", e.target.value)}
								isInvalid={!!errors.business_impact}
							>
								<option value="">Select... </option>
								{impactData?.business_impact_config?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.business_impact}
							</Form.Control.Feedback>
						</Form.Group>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn-cancel"
						onClick={() => {
							setModalOpen(false);
							clearAll();
						}}
					>
						Cancel
					</button>
					<button
						className="btn-add-new"
						onClick={(e) => {
							submit(e);
						}}
					>
						Save
					</button>
				</Modal.Footer>
			</Modal>

			<Modal
				isOpen={showHideDelete}
				onClose={() => setSetShowHideDelete(false)}
			>
				<Modal.Header>
					<Modal.Title>Delete record</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<span>Are you sure you want to delete?</span>
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn-cancel"
						onClick={() => setSetShowHideDelete(false)}
					>
						Cancel
					</button>
					<button
						className="btn-add-new"
						onClick={() => setSetShowHideDelete(false)}
					>
						Delete
					</button>
				</Modal.Footer>
			</Modal>
			{/* edit modal */}
			<Modal
				isOpen={isModalEditOpen}
				onClose={() => setModalEditOpen(false)}
				size="xl"
			>
				<Modal.Header>
					<Modal.Title>Edit Business Process</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="">
						<Form.Group>
							<Form.Label for="email">Business Process Name</Form.Label>
							<Form.Control
								type="text"
								id="text"
								value={businessProcess.businessProcessName}
								onChange={(e) =>
									setField("businessProcessName", e.target.value)
								}
								placeholder="Text..."
								required
								isInvalid={!!errors.businessProcessName}
								name="name"
							></Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.businessProcessName}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">Business Owner</Form.Label>
							<Form.Control
								type="text"
								id="text"
								value={businessProcess.business_owner}
								onChange={(e) => setField("business_owner", e.target.value)}
								placeholder="Text..."
								required
								name="name"
								isInvalid={!!errors.businessProcessName}
							></Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.business_owner}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">Application Owner</Form.Label>
							<Form.Control
								type="text"
								id="text"
								value={businessProcess.application_owner}
								onChange={(e) => setField("application_owner", e.target.value)}
								placeholder="Text..."
								required
								name="name"
								isInvalid={!!errors.application_owner}
							></Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.application_owner}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">Risk Sensitivity</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.risk_sensitivity}
								onChange={(e) => setField("risk_sensitivity", e.target.value)}
								isInvalid={!!errors.risk_sensitivity}
							>
								{/* <option
                selected
                value={
                  businessProcess.risk_sensitivity?.length > 1
                    ? businessProcess.risk_sensitivity
                    : sensetivityValue
                }
              >
                {businessProcess.risk_sensitivity?.length > 1
                  ? businessProcess.risk_sensitivity
                  : sensetivityValue}
              </option> */}
								{sensetivities?.map((x, y) => (
									<option value={x.name} key={y}>
										{x.name}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.risk_sensitivity}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">Revenue Contribution in USD</Form.Label>
							<Form.Control
								type="number"
								min={0}
								id="text"
								value={businessProcess.revenue_contribution}
								onChange={(e) =>
									setField("revenue_contribution", e.target.value)
								}
								placeholder="Enter value"
								required
								name="revenue_contribution"
								isInvalid={!!errors.revenue_contribution}
							></Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.revenue_contribution}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">Compliance Requirements</Form.Label>
							<MultipleSelectCheckmarks
								data={complianceRequirementsOptions}
								onChange={handleComplianceListOptionsChange}
								tag="Select Compliance Requirements"
								initialSelectedValues={preSelectedComplianceRequireentList}
							/>
							<Form.Control.Feedback type="invalid"></Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">
								Number of max sensitive records managed/processed by the
								application?
							</Form.Label>
							<Form.Control
								type="number"
								min={0}
								id="text"
								placeholder="Enter value"
								value={businessProcess.max_sensitive_records}
								onChange={(e) =>
									setField("max_sensitive_records", e.target.value)
								}
								required
								name="name"
								isInvalid={!!errors.max_sensitive_records}
							></Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.max_sensitive_records}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">Business Criticality (RPO)</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.business_criticality_rpo}
								onChange={(e) =>
									setField("business_criticality_rpo", e.target.value)
								}
								// value={riskATC.threat}
								// onChange={(e) => setField("threat", e.target.value)}
								isInvalid={!!errors.business_criticality_rpo}
							>
								<option value="">Select... </option>
								{state?.criticalities?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.business_criticality_rpo}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group>
							<Form.Label for="email">
								What is the highest Classification Level of the data
								held/processed by the system?
							</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.data_classification_level}
								onChange={(e) =>
									setField("data_classification_level", e.target.value)
								}
								// value={riskATC.threat}
								// onChange={(e) => setField("threat", e.target.value)}
								isInvalid={!!errors.data_classification_level}
							>
								<option>Select...</option>
								{state?.classifications?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.data_classification_level}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group>
							<Form.Label for="email">
								What is the broadest population of users of this application?
							</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.user_broadest_population}
								onChange={(e) =>
									setField("user_broadest_population", e.target.value)
								}
								isInvalid={!!errors.user_broadest_population}
							>
								<option value="">Select... </option>
								{state?.broadcasts?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.user_broadest_population}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group>
							<Form.Label for="email">
								What is the max. user population?
							</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.user_population}
								onChange={(e) => setField("user_population", e.target.value)}
								// value={riskATC.threat}
								// onChange={(e) => setField("threat", e.target.value)}
								isInvalid={!!errors.user_population}
							>
								<option value="">Select... </option>
								{state?.maxUsers?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.user_population}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group>
							<Form.Label for="email">
								How will the application be predominantly accessed?{" "}
							</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.application_accessibility}
								onChange={(e) =>
									setField("application_accessibility", e.target.value)
								}
								isInvalid={!!errors.application_accessibility}
							>
								<option value="">Select... </option>
								{state?.appAccesss?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.application_accessibility}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group>
							<Form.Label for="email">Hosting Model</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.hosting_model}
								onChange={(e) => setField("hosting_model", e.target.value)}
								isInvalid={!!errors.hosting_model}
							>
								<option value="">Select... </option>
								{state?.hostingModels?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.hosting_model}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group>
							<Form.Label for="email">
								What is the most accessible user interface for this application?
							</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.user_interface_accessibility}
								onChange={(e) =>
									setField("user_interface_accessibility", e.target.value)
								}
								isInvalid={!!errors.user_interface_accessibility}
							>
								<option value="">Select... </option>
								{state?.userInterfaces?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.user_interface_accessibility}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">
								What is the source/destination of data within the application?{" "}
							</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.data_source}
								onChange={(e) => setField("data_source", e.target.value)}
								isInvalid={!!errors.data_source}
							>
								<option value="">Select... </option>
								{state?.sources?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.data_source}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label for="email">Business Impact</Form.Label>
							<Form.Control
								as="select"
								value={businessProcess.business_impact}
								onChange={(e) => setField("business_impact", e.target.value)}
								isInvalid={!!errors.business_impact}
							>
								<option value="">Select... </option>
								{state?.businessImpacts?.map((x, y) => (
									<option value={x.id} key={y}>
										{x.label}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{errors.business_impact}
							</Form.Control.Feedback>
						</Form.Group>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn-cancel"
						onClick={() => setModalEditOpen(false)}
					>
						Cancel
					</button>
					<button
						className="btn-add-new"
						onClick={() => {
							update();
						}}
					>
						Save Change
					</button>
				</Modal.Footer>
			</Modal>

			{/* for uploading new file */}
			<ModalFileUpload
				header="Threat"
				UploadURL={UploadURL}
				fetchData={fetchData}
				selectedClientId={clientId}
				selectedBusinessProcessId={selectedBPId}
				isOpen={isModalUploadOpen}
				onClose={() => setIsModalUploadOpen(false)}
			/>
		</div>
	);
};

export default ManageBusinessProcessAdmin;
