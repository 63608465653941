import React from "react";
import { useSelector } from "react-redux";
import useNumberFormatter from "../../utils/useNumberFormatter";
import { formatNumber } from "../../utils/formatNumbers";

const StatsCard = React.memo(
	({ title, value, change, isPositive, useIndicator = false }) => {
		const client_currency = useSelector(
			(state) => state.user?.client_obj?.currency
		);
		const client_currency_symbol =
			client_currency && client_currency === "US DOLLAR"
				? "$"
				: client_currency === "EURO"
				? "€"
				: client_currency === "ETB"
				? "฿"
				: client_currency === "YEN"
				? "¥"
				: client_currency === "WON"
				? "₩"
				: client_currency === "POUND"
				? "£"
				: client_currency === "PESO"
				? "₱"
				: "$";

		return (
			<div className="bg-white  w-full h-full  dark:bg-gray-800 shadow rounded-lg p-4 flex flex-col items-center justify-between  transition-all duration-300 hover:shadow-xl">
				<div className="text-xl font-medium text-gray-500 dark:text-gray-400">
					{title}
				</div>
				<div className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">
					{client_currency_symbol}
					{formatNumber(value)}
				</div>
				{useIndicator ? (
					<div
						className={`mt-1 text-sm ${
							isPositive ? "text-green-500" : "text-red-500"
						} dark:text-${isPositive ? "green-300" : "red-300"}`}
					>
						{isPositive && isPositive ? "▲" : "▼"} {change}%
					</div>
				) : (
					<div></div>
				)}
			</div>
		);
	}
);

export default StatsCard;
