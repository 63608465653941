import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import http from "../../../../resources/http";
import { Link } from "react-router-dom";
import Dist from "./Dist";
import { products } from "../../../../authentication/store/actions";
import { baseURL } from "../../../../resources/apiClient";
import LoadingSpinner from "../../../../utils/LoadingSpinner";
import VulnerabilityHeatMap from "../../../../Client/Analysis/BusinessImpactAnalysis/VulnerabilityHeatMap";
import VulnerabilityCards from "../../../../Client/Analysis/BusinessImpactAnalysis/VulnerabilityCards";
import VulnerabilityAccordion from "../../../../Client/Analysis/BusinessImpactAnalysis/VulnerabilityAccordion";
import axios from "axios";
import { useQuery } from "@apollo/client";
import { GET_VELNERABILITY_SUMMARY } from "../../../../graphql/client/query";

const VendersDetail = ({ textMe }) => {
  const selectedVendor = useSelector((state) => state.vendor);
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const cpeValue = useSelector((state) => state.cpe);
  const selectedProduct = useSelector((state) => state.pr);
  const processId = useSelector((state) => state.pid);
  let type = JSON.parse(localStorage.getItem("cyber-minds"));
  let clientID = type?.user?.client;
  const [pageNumber, setPageNumber] = useState(0);
  console.log(cpeValue, "cpeValue");

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;

  useEffect(() => {
    http
      .get(
        `${baseURL}/excel-upload/assets?clientId=${clientID}&processId=${processId}`
      )
      .then((response) => {
        setDatas(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log("No Data To Show");
        }
      )
      .catch((err) => {
        return false;
      });
    axios
      .get(
        `https://services.nvd.nist.gov/rest/json/cves/2.0?cpeName=cpe:2.3:o:linux:linux_kernel:5.10.0:*:*:*:*:*:*:*`
      )
      .then((response) => {
        setData(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
  }, []);
  console.log(datas, "datas");
  //Detail properties to displied for every product
  const critical = data
    ?.filter(
      (vendors) =>
        vendors?.metrics?.cvssMetricV2?.[0]?.baseSeverity === "CRITICAL"
    )
    .map((record) => record?.metrics?.cvssMetricV2?.[0]?.baseSeverity);
  const high = data
    ?.filter(
      (vendors) => vendors?.metrics?.cvssMetricV2?.[0]?.baseSeverity === "HIGH"
    )
    .map((record) => record?.metrics?.cvssMetricV2?.[0]?.baseSeverity);
  console.log(high.length, "length of high");
  const medium = data
    ?.filter(
      (vendors) =>
        vendors?.metrics?.cvssMetricV2?.[0]?.baseSeverity === "MEDIUM"
    )
    .map((record) => record?.metrics?.cvssMetricV2?.[0]?.baseSeverity);
  const low = data
    ?.filter(
      (vendors) => vendors?.metrics?.cvssMetricV2?.[0]?.baseSeverity === "LOW"
    )
    .map((record) => record?.metrics?.cvssMetricV2?.[0]?.baseSeverity);
  console.log(selectedVendor, "product new set");
  const {
    data: cpeData,
    loading,
    error,
    refetch,
  } = useQuery(GET_VELNERABILITY_SUMMARY, {
    variables: { cpe: cpeValue },
  });

  const DisplayCpeData = cpeData?.cpe_summary?.data
    ?.filter((vendors) => vendors?.product === selectedProduct)
    .map((risk) => {
      return (
        <tr>
          <td className="product_color">{risk.product}</td>
          <td className="critical_color">{critical.length}</td>
          <td className="high_color">{high.length}</td>
          <td className="medium_color">{medium.length}</td>
          <td className="low_color">{low.length}</td>
        </tr>
      );
    });
  const vulnerabilities = [
    {
      id: "CVE-2014-3753",
      sourceIdentifier: "cve@mitre.org",
      published: "2020-01-09T14:15:11.420",
      lastModified: "2024-11-21T02:08:46.567",
      vulnStatus: "Modified",
      descriptions: [
        {
          lang: "en",
          value:
            "AgileBits 1Password through 1.0.9.340 allows security feature bypass",
        },
        {
          lang: "es",
          value:
            "AgileBits 1Password por medio de 1.0.9.340, permite omitir la caracter\\u00edstica de seguridad.",
        },
      ],
      metrics: {
        cvssMetricV31: [
          {
            source: "nvd@nist.gov",
            type: "Primary",
            cvssData: {
              version: "3.1",
              vectorString: "CVSS:3.1/AV:L/AC:L/PR:N/UI:R/S:U/C:H/I:N/A:N",
              baseScore: 5.5,
              baseSeverity: "MEDIUM",
              attackVector: "LOCAL",
              attackComplexity: "LOW",
              privilegesRequired: "NONE",
              userInteraction: "REQUIRED",
              scope: "UNCHANGED",
              confidentialityImpact: "HIGH",
              integrityImpact: "NONE",
              availabilityImpact: "NONE",
            },
            exploitabilityScore: 1.8,
            impactScore: 3.6,
          },
        ],
        cvssMetricV2: [
          {
            source: "nvd@nist.gov",
            type: "Primary",
            cvssData: {
              version: "2.0",
              vectorString: "AV:N/AC:M/Au:N/C:P/I:N/A:N",
              baseScore: 4.3,
              accessVector: "NETWORK",
              accessComplexity: "MEDIUM",
              authentication: "NONE",
              confidentialityImpact: "PARTIAL",
              integrityImpact: "NONE",
              availabilityImpact: "NONE",
            },
            baseSeverity: "MEDIUM",
            exploitabilityScore: 8.6,
            impactScore: 2.9,
            acInsufInfo: false,
            obtainAllPrivilege: false,
            obtainUserPrivilege: false,
            obtainOtherPrivilege: false,
            userInteractionRequired: true,
          },
        ],
      },
      weaknesses: [
        {
          source: "nvd@nist.gov",
          type: "Primary",
          description: [{ lang: "en", value: "CWE-200" }],
        },
      ],
      configurations: [
        {
          nodes: [
            {
              operator: "OR",
              negate: false,
              cpeMatch: [
                {
                  vulnerable: true,
                  criteria:
                    "cpe:2.3:a:1password:1password:*:*:*:*:*:windows:*:*",
                  versionEndIncluding: "1.0.9.340",
                  matchCriteriaId: "DD89D5C9-BDA0-459A-9468-C9ED3975578C",
                },
              ],
            },
          ],
        },
      ],
      references: [
        {
          url: "https://www.trustwave.com/en-us/resources/security-resources/security-advisories/?fid=18986",
          source: "cve@mitre.org",
          tags: ["Exploit", "Third Party Advisory"],
        },
        {
          url: "https://www.trustwave.com/en-us/resources/security-resources/security-advisories/?fid=18986",
          source: "af854a3a-2127-422b-91ae-364da2661108",
          tags: ["Exploit", "Third Party Advisory"],
        },
      ],
    },
  ];

  console.log(vulnerabilities);


  const DisplayData = vulnerabilities
    ?.slice(pagesVisited, pagesVisited + usersPerPage)
    ?.map((vender) => {
      return (
        <tr>
          <td className="color_id font-semibold">{vender?.id}</td>
          <td className="table_width">{vender?.descriptions?.[0]?.value}</td>
          <td>{vender?.metrics?.cvssMetricV2?.[0]?.exploitabilityScore}</td>
          <td>{vender?.metrics?.cvssMetricV2?.[0]?.baseSeverity}</td>
          <td>{vender?.metrics?.cvssMetricV2?.[0]?.impactScore}</td>
        </tr>
      );
    });
  console.log(data, "critical");
  const pageCount = Math.ceil(data?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  
  return (
    <div>
      {true ? (
        <div className="z-100  flex flex-col space-y-10 justify-center relative p-10 items-center">
          <div className="w-96">
            <div className="rounded overflow-hidden h-full shadow-md flex flex-col  justify-center items-center">
              <div className="flex flex-col justify-center items-center space-y-4 w-full">
                <div className="flex space-x-4">
                  <h3 className="text-4xl font-semibold">
                    Vulnerability Heat Map
                  </h3>
                </div>
                <table className="text-black tables h-full">
                  <thead>
                    <tr>
                      <th className="product_color">Product</th>
                      <th className="critical_color">Critical</th>
                      <th className="high_color">High</th>
                      <th className="medium_color">Medium</th>
                      <th className="low_color">Low</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="product_color">{selectedVendor}</td>
                      <td className="critical_color">{critical.length}</td>
                      <td className="high_color">{high.length}</td>
                      <td className="medium_color">{medium.length}</td>
                      <td className="low_color">{low.length}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="text-black ">
            <div className="rounded overflow-hidden flex  justify-center items-center">
              <table class="tables">
                <thead>
                  <tr>
                    <th>Vuln ID</th>
                    <th>Description Data</th>
                    <th>Exploitability Score</th>
                    <th>Severity</th>
                    <th>Impact Score</th>
                  </tr>
                </thead>
                <tbody>{DisplayData}</tbody>
              </table>
            </div>

            <div className="w-full">
              {data.length > 10 && (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        // <div>
        //   <VulnerabilityCards vulnerabilities={data} />
        // </div>
        <LoadingSpinner />
      )}
    </div>
  );
};

export default VendersDetail;
