import React, { useEffect, useState } from 'react';
import {
  GET_THREAT_CATEGORIES,
  GET_DISCOVERABILITY_LEVELS,
  GET_EXPLOITABILITY_LEVELS,
  GET_REPRODUCIBILITIES,
  GET_AVAILABILITY_LEVELS,
  GET_INTEGRITY_LEVELS,
  GET_CONFIDENTIALITY_LEVELS,
  ADD_THREAT_VECTOR,
  DELETE_THREAT_VECTOR,
  UPDATE_THREAT_VECTOR,
} from '../../graphql/superAdmin/query/query';
import { Button, Form, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import { useQuery, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';

const ThreatRepositoryModal = ({
  selectedItemId,
  action,
  handleModalShowHide,
  payload,
  fetchData,
}) => {
  const [threatVectors, setThreatVectors] = useState({
    name: '',
    threatCategory: '',
    discoverability: payload?.discovery_level?.difficulty_level??'',
    exploitability: payload?.exploitable_level?.difficulty_level??'',
    reproducibility: payload?.reproducibility_level?.difficulty_level??'',
    confidentiality: payload?.confidentiality_level?.difficulty_level??'',
    availability: payload?.availability_level?.difficulty_level??'',
    integrity: payload?.integrity_level?.difficulty_level??'',
    threatVectorIncident: '',
    costOfIncident: '',
    costOfBreach: '',
    percentOfBreach: '',
    lostOfBusinessCost: '',
    threatDescription: '',
    threatScenario: '',
  });

  let localStorageUserData = JSON.parse(localStorage.getItem("cyber-minds"));
  const clientID = localStorageUserData?.user?.client?.id;

  const { data: categoriesData } = useQuery(GET_THREAT_CATEGORIES, {
    variables: { limit: 10 },
  });
  const { data: discoverData } = useQuery(GET_DISCOVERABILITY_LEVELS, {
    variables: { limit: 10 },
  });

  const { data: exploitData } = useQuery(GET_EXPLOITABILITY_LEVELS, {
    variables: { limit: 10 },
  });
  const { data: reproduceData } = useQuery(GET_REPRODUCIBILITIES, {
    variables: { limit: 10 },
  });
  const { data: availableData } = useQuery(GET_AVAILABILITY_LEVELS, {
    variables: { limit: 10 },
  });
  const { data: integritiesData } = useQuery(GET_INTEGRITY_LEVELS, {
    variables: { limit: 10 },
  });
  const { data: confidentData } = useQuery(GET_CONFIDENTIALITY_LEVELS, {
    variables: { limit: 10 },
  });

  const [createThreat] = useMutation(ADD_THREAT_VECTOR);
  const [updateThreat] = useMutation(UPDATE_THREAT_VECTOR);

  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (action === 'edit' && payload) {
      setThreatVectors({
        name: payload.name || '',
        threatCategory: payload.category || '',
        discoverability: payload?.discovery_level?.id??'',
        exploitability: payload?.exploitable_level?.id??'',
        reproducibility: payload?.reproducibility_level?.id??'',
        confidentiality: payload?.confidentiality_level?.id??'',
        availability: payload?.availability_level?.id??'',
        integrity: payload?.integrity_level?.id??'',
        threatVectorIncident: payload.event_frequency || '',
        costOfIncident: payload.cost_of_incident || '',
        costOfBreach: payload.cost_of_breach || '',
        percentOfBreach: payload.percent_of_breach || '',
        lostOfBusinessCost: payload.lost_business_cost || '',
        threatDescription: payload.description || '',
        threatScenario: payload.threat_senario || '',
      });
    }
  }, [action, payload]);

  const submit = async (e) => {
    e.preventDefault();
    const assetErrors = validateThreatRepository();
    if (Object.keys(assetErrors).length > 0) {
      setErrors(assetErrors);
    } else {
      try {
        let response = await createThreat({
          variables: {
            inputs: {
              created_at: new Date().toISOString(),
              name: threatVectors.name,
              description: threatVectors.threatDescription,
              discovery_level_pk: parseInt(threatVectors.discoverability),
              exploitable_level_pk: parseInt(threatVectors.exploitability),
              reproducibility_level_pk: parseInt(threatVectors.reproducibility),
              confidentiality_level_pk: parseInt(threatVectors.confidentiality),
              integrity_level_pk: parseInt(threatVectors.integrity),
              availability_level_pk: parseInt(threatVectors.availability),
              event_frequency: parseFloat(threatVectors.threatVectorIncident),
              cost_of_incident: parseFloat(threatVectors.costOfIncident),
              cost_of_breach: parseFloat(threatVectors.costOfBreach),
              percent_of_breach: parseFloat(threatVectors.percentOfBreach),
              lost_business_cost: parseFloat(threatVectors.lostOfBusinessCost),
              client_pk:parseInt(clientID),
            },
          },
        });
        if (response.data.create_threat.affected_rows > 0) {
          toast.success('Threat added successfully!');
          fetchData();
          handleModalShowHide();
        } else if (response && response.errors && response.errors.length > 0) {
          // Extract the first error message
          const errorMessage =
            response.errors[0].message ||
            'An error occurred while updating the client.';
          toast.error(errorMessage);
        }
      } catch (error) {
        toast.error('An unexpected error occurred. Please try again.');
        console.error(error);
      }
    }
  };
  const update = async (e) => {
    e.preventDefault();
    const assetErrors = validateThreatRepository();
    if (Object.keys(assetErrors).length > 0) {
      setErrors(assetErrors);
    } else {
      try {
        let response = await updateThreat({
          variables: {
            id: parseInt(payload.id),
            input: {
              created_at: new Date().toISOString(),
              name: threatVectors.name,
              description: threatVectors.threatDescription,
              discovery_level_pk: parseInt(threatVectors.discoverability),
              exploitable_level_pk: parseInt(threatVectors.exploitability),
              reproducibility_level_pk: parseInt(threatVectors.reproducibility),
              confidentiality_level_pk: parseInt(threatVectors.confidentiality),
              integrity_level_pk: parseInt(threatVectors.integrity),
              availability_level_pk: parseInt(threatVectors.availability),
              event_frequency: parseFloat(threatVectors.threatVectorIncident),
              cost_of_incident: parseFloat(threatVectors.costOfIncident),
              cost_of_breach: parseFloat(threatVectors.costOfBreach),
              percent_of_breach: parseFloat(threatVectors.percentOfBreach),
              lost_business_cost: parseFloat(threatVectors.lostOfBusinessCost),
            },
          },
        });
        if (response.data.update_threat.affected_rows > 0) {
          toast.success('Threat updated successfully!');
          fetchData();
          handleModalShowHide();
        } else if (response && response.errors && response.errors.length > 0) {
          // Extract the first error message
          const errorMessage =
            response.errors[0].message ||
            'An error occurred while updating the client.';
          toast.error(errorMessage);
        }
      } catch (error) {
        toast.error('An unexpected error occurred. Please try again.');
        console.error(error);
      }
    }
  };

  const setField = (field, value) => {
    setThreatVectors((prev) => ({
      ...prev,
      [field]: value,
    }));
    if (errors[field]) {
      setErrors((prev) => ({
        ...prev,
        [field]: null,
      }));
    }
  };

  const validateThreatRepository = () => {
    const {
      name,
      threatCategory,
      discoverability,
      exploitability,
      reproducibility,
      confidentiality,
      availability,
      integrity,
    } = threatVectors;
    const newErrors = {};

    if (!name || name === 'Select...')
      newErrors.name = 'Threat Vector is required.';
    if (!threatCategory || threatCategory === 'Select...')
      newErrors.threatCategory = 'Threat Category is required.';
    if (!discoverability || discoverability === 'Select...')
      newErrors.discoverability = 'Discoverability is required';
    if (!exploitability || exploitability === 'Select...')
      newErrors.exploitability = 'Exploitability is required.';
    if (!reproducibility || reproducibility === 'Select...')
      newErrors.reproducibility = 'Reproducibility is required.';
    if (!confidentiality || confidentiality === 'Select...')
      newErrors.confidentiality = 'Confidentiality is required.';
    if (!integrity || integrity === 'Select...')
      newErrors.integrity = 'Integrity is required.';
    if (!availability || availability === 'Select...')
      newErrors.availability = 'Availability is required.';

    return newErrors;
  };

  const clearFields = () => {
    setThreatVectors({
      name: '',
      threatCategory: '',
      discoverability: '',
      exploitability: '',
      reproducibility: '',
      confidentiality: '',
      availability: '',
      integrity: '',
      threatVectorIncident: '',
      costOfIncident: '',
      costOfBreach: '',
      percentOfBreach: '',
      lostOfBusinessCost: '',
      threatDescription: '',
      threatScenario: '',
    });
  };

  return (
    <div className="p-10">
      <Modal show={true}>
        <Modal.Header className="flex justify-center">
          <Modal.Title>
            {action === 'add' ? 'Add New' : 'Edit'} Threat Vector
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-three-columns">
            <Form.Group className="mb-6">
              <Form.Label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Threat Vector Name
              </Form.Label>
              <Form.Control
                type="text"
                id="name"
                value={threatVectors.name}
                onChange={(e) => setField('name', e.target.value)}
                className="h-12 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Text..."
                isInvalid={!!errors.name}
                autoFocus
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-6 sr-only">
              <Form.Label
                htmlFor="threatCategory"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Threat Category
              </Form.Label>
              <Form.Control
                as="select"
                id="threatCategory"
                value={threatVectors.threatCategory}
                onChange={(e) => setField('threatCategory', e.target.value)}
                className="h-12 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                isInvalid={!!errors.threatCategory}
                required
              >
                <option value="">Select...</option>
                {categoriesData &&
                  categoriesData?.reproducibility_level?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.difficulty_level}
                    </option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.threatCategory}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-6">
              <Form.Label
                htmlFor="discoverability"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Discoverability
              </Form.Label>
              <Form.Control
                as="select"
                id="discoverability"
                value={threatVectors.discoverability}
                onChange={(e) => setField('discoverability', e.target.value)}
                className="h-12 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                isInvalid={!!errors.discoverability}
                required
              >
                <option value="">Select...</option>
                {discoverData &&
                  discoverData?.discovery_level?.map((level) => (
                    <option key={level.id} value={level.id}>
                      {level.difficulty_level}
                    </option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.discoverability}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-6">
              <Form.Label
                htmlFor="exploitability"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Exploitability
              </Form.Label>
              <Form.Control
                as="select"
                id="exploitability"
                value={threatVectors.exploitability}
                onChange={(e) => setField('exploitability', e.target.value)}
                className="h-12 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                isInvalid={!!errors.exploitability}
                required
              >
                <option value="">Select...</option>
                {exploitData &&
                  exploitData?.exploitable_level?.map((level) => (
                    <option key={level.id} value={level.id}>
                      {level.difficulty_level}
                    </option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.exploitability}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-6">
              <Form.Label
                htmlFor="reproducibility"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Reproducibility
              </Form.Label>
              <Form.Control
                as="select"
                id="reproducibility"
                value={threatVectors.reproducibility}
                onChange={(e) => setField('reproducibility', e.target.value)}
                className="h-12 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                isInvalid={!!errors.reproducibility}
                required
              >
                <option value="">Select...</option>
                {reproduceData &&
                  reproduceData?.reproducibility_level?.map((level) => (
                    <option key={level.id} value={level.id}>
                      {level.difficulty_level}
                    </option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.reproducibility}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-6">
              <Form.Label
                htmlFor="confidentiality"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Confidentiality
              </Form.Label>
              <Form.Control
                as="select"
                id="confidentiality"
                value={threatVectors.confidentiality}
                onChange={(e) => setField('confidentiality', e.target.value)}
                className="h-12 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                isInvalid={!!errors.confidentiality}
                required
              >
                <option value="">Select...</option>
                {confidentData &&
                  confidentData?.confidentiality_level?.map((level) => (
                    <option key={level.id} value={level.id}>
                      {level.difficulty_level}
                    </option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.confidentiality}
              </Form.Control.Feedback>
            </Form.Group>


            <Form.Group className="mb-6">
              <Form.Label
                htmlFor="integrity"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Integrity
              </Form.Label>
              <Form.Control
                as="select"
                id="integrity"
                value={threatVectors.integrity}
                onChange={(e) => setField('integrity', e.target.value)}
                className="h-12 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                isInvalid={!!errors.integrity}
                required
              >
                <option value="">Select...</option>
                {integritiesData &&
                  integritiesData?.integrity_level?.map((level) => (
                    <option key={level.id} value={level.id}>
                      {level.difficulty_level}
                    </option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.integrity}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-6">
              <Form.Label
                htmlFor="availability"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Availability
              </Form.Label>
              <Form.Control
                as="select"
                id="availability"
                value={threatVectors.availability}
                onChange={(e) => setField('availability', e.target.value)}
                className="h-12 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                isInvalid={!!errors.availability}
                required
              >
                <option value="">Select...</option>
                {availableData &&
                  availableData?.availability_level?.map((level) => (
                    <option key={level.id} value={level.id}>
                      {level.difficulty_level}
                    </option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.availability}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div className="modal-three-columns">
            <Form.Group className="mb-6">
              <Form.Label
                htmlFor="threatVectorIncident"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Threat Vector Incident
              </Form.Label>
              <Form.Control
                type="number"
                id="threatVectorIncident"
                value={threatVectors.threatVectorIncident}
                onChange={(e) =>
                  setField('threatVectorIncident', e.target.value)
                }
                className="h-12 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Number"
              />
            </Form.Group>

            <Form.Group className="mb-6">
              <Form.Label
                htmlFor="costOfIncident"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Cost Of Incident
              </Form.Label>
              <Form.Control
                type="number"
                id="costOfIncident"
                value={threatVectors.costOfIncident}
                onChange={(e) => setField('costOfIncident', e.target.value)}
                className="h-12 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Number"
              />
            </Form.Group>

            <Form.Group className="mb-6">
              <Form.Label
                htmlFor="costOfBreach"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Cost Of Breach
              </Form.Label>
              <Form.Control
                type="number"
                id="costOfBreach"
                value={threatVectors.costOfBreach}
                onChange={(e) => setField('costOfBreach', e.target.value)}
                className="h-12 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Number"
              />
            </Form.Group>

            <Form.Group className="mb-6">
              <Form.Label
                htmlFor="percentOfBreach"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Percent Of Breach
              </Form.Label>
              <Form.Control
                type="number"
                id="percentOfBreach"
                value={threatVectors.percentOfBreach}
                onChange={(e) => setField('percentOfBreach', e.target.value)}
                className="h-12 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Number"
              />
            </Form.Group>

            <Form.Group className="mb-6">
              <Form.Label
                htmlFor="lostOfBusinessCost"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Lost Of Business Cost
              </Form.Label>
              <Form.Control
                type="number"
                id="lostOfBusinessCost"
                value={threatVectors.lostOfBusinessCost}
                onChange={(e) => setField('lostOfBusinessCost', e.target.value)}
                className="h-12 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Number"
              />
            </Form.Group>

            <Form.Group className="mb-6">
              <Form.Label
                htmlFor="threatDescription"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Threat Description
              </Form.Label>
              <Form.Control
                as="textarea"
                id="threatDescription"
                value={threatVectors.threatDescription}
                onChange={(e) => setField('threatDescription', e.target.value)}
                className="h-12 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                rows={3}
                placeholder="Text..."
              />
            </Form.Group>

            <Form.Group className="mb-6">
              <Form.Label
                htmlFor="threatScenario"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Threat Scenario
              </Form.Label>
              <Form.Control
                as="textarea"
                id="threatScenario"
                value={threatVectors.threatScenario}
                onChange={(e) => setField('threatScenario', e.target.value)}
                className="h-12 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                rows={3}
                placeholder="Text..."
              />
            </Form.Group>
          </div>

          <div className="flex justify-end items-center space-x-2">
            <button className='btn-cancel' onClick={handleModalShowHide}>
              Cancel
            </button>
            {action === 'add' ? (
              <button className="btn-add-new"onClick={submit}>
                Add
              </button>
            ) : (
              <button className="btn-add-new" onClick={update}>
              Save Changes
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ThreatRepositoryModal;
